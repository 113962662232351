import React, { useState } from "react";

const PreviewFile = ({
  pdfFile,
  setPdfFile,
  previewURL,
  setPreviewURL,
  onRemove,
}) => {
  // const [aa, setAA] = useState();
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type === "application/pdf") {
      setPdfFile(file);
      const fileURL = file.name;
      // setAA(URL.createObjectURL(file));
      setPreviewURL(fileURL);
    } else {
      setPdfFile(null);
      setPreviewURL(null);
    }
  };

  const handleRemoveFile = () => {
    if (previewURL) {
      onRemove(previewURL);
      setPdfFile(null);
      setPreviewURL(null);
    }
  };

  return (
    <div className="pdf-upload-preview max-w-2xl mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">
        Specification PDF File Upload
      </h2>

      {previewURL ? (
        <div className="mb-4">
          <input
            type="text"
            value={previewURL}
            readOnly
            className="mb-2 border rounded-lg px-4 py-2 block w-full bg-gray-100 text-gray-700"
          />
          <button
            onClick={handleRemoveFile}
            className="text-red-500 text-sm hover:underline"
          >
            Remove File
          </button>
          {/* <div>
            <h3 className="text-lg font-semibold mb-2">Preview:</h3>
            <div
              className="border rounded-lg p-2 overflow-y-scroll h-96"
              style={{ backgroundColor: "#f9f9f9" }}
            >
              <iframe
                src={aa}
                title="PDF Preview"
                width="100%"
                height="100%"
                style={{ border: "none" }}
              ></iframe>
            </div>
          </div> */}
        </div>
      ) : (
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          className="mb-4 border rounded-lg px-4 py-2 block w-full"
        />
      )}
    </div>
  );
};

export default PreviewFile;
