import React, { useContext, useEffect, useMemo, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import useSWR from "swr";

import useSWRMutation from "swr/mutation";

import CustomizePublishCell from "../../../src/components/Table/Components/CustomizePublishCell";

import CustomizeDateCell from "../../../src/components/Table/Components/CustomizeDateCell";

import ActionComponent from "../../../src/components/Table/Components/ActionComponent";

import CustomAlert from "../../../src/components/Notification/CustomAlert";

import LoadingComponent from "../../../src/components/Loading";

import CustomizedTable from "../../../src/components/Table/CustomizedTable";

import FormModal from "../../../src/components/FormModal";

import MainLoading from "../../../src/components/MainLoading";

import { ErrContext } from "../../providers/ErrorProvider";

import "react-toastify/dist/ReactToastify.css";

import NewsForm from "../../components/Forms/NewsForm";
import CustomizedImageCell from "../../../src/components/Table/Components/CustomizedImageCell";
import Header from "../../components/PageHeader";
import axiosClient from "../../axios-client";

export default function NewsPage() {
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const [isShowCreate, setIsShowCreate] = useState(false);

  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,

    message: "",

    type: "",

    btnLabel: "",

    btnColor: "",

    onBtnClick: null,
  });

  const defaultData = {
    _id: "",

    title: "",

    titlemm: "",

    content: "",

    contentmm: "",

    publish: false,
    imageStore: [],
    imagefile: [],
    fromDate: "",
  };

  const [formData, setFormData] = useState(defaultData);
  const [setIsError, setErrMsg] = useContext(ErrContext);

  const fetchData = async ([pageParams, limitParams]) => {
    let resp;

    resp = await axiosClient
      .get(`/backpanel/news?page=${pageParams}&limit=${limitParams}`)
      .then((response) => response?.data)
      .catch((err) => {
        setIsError(true);
        setErrMsg({ code: err.response.status, msg: err.message });
      });

    return resp;
  };

  const { data: news, mutate, isLoading } = useSWR([page, limit], fetchData);

  const { trigger, isMutating } = useSWRMutation([page, limit], fetchData);

  useEffect(() => {
    trigger({ ...news, page, limit });

    mutate();
  }, []);

  const createNews = async (body) => {
    const resp = await axiosClient

      .post(`/backpanel/news`, body)

      .then(async (response) => {
        mutate();

        toast(response?.data?.message, { type: "success" });

        return response;
      })

      .catch((err) =>
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        })
      );

    return resp;
  };

  const editData = async (row) => {
    const tableData = {
      _id: row._id,
      title: row.title,
      titlemm: row.titlemm,
      content: row.content,
      contentmm: row.contentmm,
      publish: row.publish,
      image: row.imagefile,
      imagefile: row.imagefile,
      posteddate: row.posteddate,
    };

    setFormData(tableData);
    setIsOpenEdit(true);
  };

  const updateNews = async (body, id) => {
    const resp = await axiosClient
      .put(`/backpanel/news/${id}`, body)

      .then(async (response) => {
        await mutate();

        setIsOpenEdit(false);

        toast(response.data.message, { type: "success" });

        return response;
      })

      .catch((err) => {
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });

    setFormData({
      ...defaultData,
    });

    return resp;
  };

  const submitNew = async (data, type, newImages, id, removeImages) => {
    if (type === "create") {
      const formData = new FormData();

      if (newImages.length != 0) {
        for (let i = 0; i < newImages.length; i++) {
          formData.append("imagefile", newImages[i]);
        }
      }

      formData.append("publish", data.publish);

      formData.append("title", data.title);

      formData.append("titlemm", data.titlemm);

      formData.append("content", data.content);

      formData.append("contentmm", data.contentmm);

      formData.append("posteddate", Date.parse(data.posteddate));

      const resp = await createNews(formData);

      if (resp) {
        setIsShowCreate(false);
      }
    } else if (type === "edit") {
      const fData = new FormData();

      if (newImages.length != 0) {
        for (let i = 0; i < newImages.length; i++) {
          fData.append("imagefile", newImages[i]);
        }
      }

      fData.append("title", data.title);
      fData.append("titlemm", data.titlemm);
      fData.append("content", data.content);
      fData.append("contentmm", data.contentmm);
      fData.append("publish", data.publish);
      fData.append("posteddate", Date.parse(data.posteddate));
      fData.append(
        "removeImage",
        removeImages ? JSON.stringify(removeImages) : JSON.stringify([])
      );

      updateNews(fData, id);
    } else {
    }
  };

  const deleteData = async (row) => {
    setAlertState({
      ...alertState,

      open: false,
    });

    await axiosClient
      .delete(`/backpanel/news/${row?._id}`)

      .then((response) => {
        toast(response.data.message, { type: "success" });
      })

      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    await trigger({ ...news, page, limit });
    await mutate();
  };

  const columns = useMemo(() => {
    if (!news) return [];

    const columnData = [
      {
        Header: "Action",

        accessor: "action",

        filter: false,

        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),

        width: 100,
      },

      ...news?.columns,
    ];

    const changeCol = columnData?.map((col) => {
      const column = col;

      if (column.accessor === "publish") {
        column.Cell = ({ cell }) => CustomizePublishCell({ cell });

        column.width = 50;
      }

      if (column.accessor?.includes("imagefile")) {
        column.Cell = ({ cell }) => CustomizedImageCell({ cell });

        column.width = 200;
      }

      if (column.accessor === "posteddate") {
        column.Cell = ({ cell }) => CustomizeDateCell({ cell });

        column.width = 200;
      }

      return column;
    });

    return changeCol;
  }, [news]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;

      tempColumn.show = true;

      return tempColumn;
    });

    return global;
  }, [columns]);

  if (isLoading || !news || isMutating) {
    return <MainLoading enter={isLoading || isMutating} bg="#000F3C4D" />;
  }

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isMutating} />

      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>
      <Header pageName="News" />
      <CustomizedTable
        dataRows={news?.data}
        dataColumns={columns}
        totalItems={news.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        loading={isLoading}
        onCreate={() => setIsShowCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...news, page, limit })}
        colWithShow={globalColumn}
      />

      <FormModal isOpen={isShowCreate} header="Create News">
        <NewsForm
          type="createForm"
          formData={formData}
          onClose={() => setIsShowCreate(false)}
          onConfirm={(data, newImages) => submitNew(data, "create", newImages)}
        />
      </FormModal>

      <FormModal
        isOpen={isOpenEdit}
        onClose={() => {
          setIsOpenEdit(false);
        }}
        header="Edit News"
      >
        <NewsForm
          type="editForm"
          formData={formData}
          onClose={() => {
            setFormData({
              ...formData,
            });

            setIsOpenEdit(false);
          }}
          onConfirm={(data, newImages, removeImages) =>
            submitNew(data, "edit", newImages, formData?._id, removeImages)
          }
        />
      </FormModal>

      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      />
    </>
  );
}
