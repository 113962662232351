import data from "./data";
import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";

export default function SidenavItems() {
  const style = {
    inactive: "text-[#2196f3]",
    active: "font-medium text-[#2962ff]",
    link: "flex items-center justify-start my-2 p-4 text-sm w-full group-hover:text-[#2962ff]",
  };

  const [activeMenu, setActiveMenu] = useState("Banner");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname?.includes("brand")) {
      setOpen(true);
      setActiveMenu("Vehicle");
    }
  }, [location?.pathname]);

  const handleClickTitle = (link, title) => {
    setActiveMenu(title);
    if (title === "Vehicle") setOpen(!open);

    if (title !== "Vehicle") navigate(link);
  };

  const [permission, setPermission] = useState([]);
  const [filterPermission, setFilterPermission] = useState();

  useEffect(() => {
    fetch(`/backpanel/permissions`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPermission(data?.PERMISSIONS_LIST_BY_PAGE);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  const access_token = localStorage?.getItem("access_token");
  const decodedToken = jwtDecode(access_token);

  const permiss = decodedToken?.permissions || [];

  return (
    <>
      <ul className="tablet:pl-6">
        <li>
          {data
            ?.filter(
              (item) =>
                permiss.includes("superadmin") ||
                (item?.permission &&
                  Array.isArray(item?.permission) &&
                  item?.permission?.some((element) =>
                    permiss?.includes(element)
                  ))
            )
            ?.map((item, i) => (
              <div key={`submenu-${i}`}>
                <div
                  className={`${
                    activeMenu === item?.title ? style.active : style.inactive
                  } flex items-center cursor-pointer group
                  `}
                  onClick={() => handleClickTitle(item?.link, item?.title)}
                >
                  <span className={`flex items-center  ${style.link}`}>
                    <span>{item?.icon}</span>
                    <span className="mx-4 ">{item?.title}</span>
                  </span>

                  <div className="mr-6 cursor-pointer">
                    {activeMenu === item.title && open ? (
                      <>
                        <IoIosArrowUp
                          className={`h-[20px] w-[20px] group-hover:text-[#2962ff]  ${
                            activeMenu === item.title
                              ? "text-[#2962ff]"
                              : "text-[#2196f3]"
                          }  ${
                            item.title === "Vehicle" ? "block" : "hidden"
                          }   `}
                        />
                      </>
                    ) : (
                      <IoIosArrowDown
                        className={`h-[20px] w-[20px] group-hover:text-[#2962ff]  ${
                          activeMenu === item.title
                            ? "text-[#2962ff]"
                            : "text-[#2196f3]"
                        } ${item.title === "Vehicle" ? "block" : "hidden"}`}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full ">
                  {activeMenu === item?.title && open ? (
                    <ul className=" flex w-[100%] flex-col  rounded-[8px]   text-[#fff]">
                      {item?.options?.map((option, index) => (
                        <div
                          key={index}
                          className="w-full "
                          style={{ textDecoration: "none" }}
                          onClick={() => navigate(option.link)}
                        >
                          <div
                            className={`flex w-full cursor-pointer flex-col    px-14  py-4 hover:rounded-[8px] hover:text-[#2962ff] ${
                              location.pathname.includes(
                                option.title.toLowerCase()
                              )
                                ? "text-[#2962ff]"
                                : "text-[#2196f3]"
                            }`}
                          >
                            <p>{option.title}</p>
                          </div>
                        </div>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </li>
      </ul>
    </>
  );
}
