import React from "react";
import PropTypes from "prop-types";

function CustomizePublishCell({ cell }) {
  return (
    <div
      className={` font-semibold rounded-md py-2 ${
        cell.value === true
          ? "bg-[#2962ff] text-white"
          : "bg-[#c3c3c3] text-black"
      }`}
    >
      {" "}
      {cell.value === true ? "Publish" : "Unpublish"}{" "}
    </div>
  );
}
CustomizePublishCell.propTypes = {
  cell: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.bool])
  ).isRequired,
};

export default CustomizePublishCell;
