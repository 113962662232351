import React from "react";
import ReactDOM from "react-dom/client";

import "../src/styles/globals.css";
import { routes } from "./routes";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import ErrorProvider from "./providers/ErrorProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorProvider>
    <RouterProvider router={routes} />
  </ErrorProvider>
);
reportWebVitals();
