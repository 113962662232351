import React from "react";
import PropTypes from "prop-types";
import SidenavItems from "./items";
import SidenavHeader from "./header";
import { useToggle } from "../../dashboard/provider/context";

export default function SideNavigation({ mobilePosition }) {
  const style = {
    mobilePosition: {
      left: "left-0",
      right: "right-0",
    },
    close: "hidden",
    container: "pb-32 laptop:pb-12",
    open: "absolute w-8/12 z-40 tablet:w-5/12",
    default: `${"bg-[#f5f5f5]"}   h-screen overflow-y-auto scrollable-container  top-0 laptop:flex laptop:relative laptop:w-64 laptop:z-auto `,
  };
  const { open, ref } = useToggle();

  return (
    <div>
      <aside
        className={`${style.default} ${style.mobilePosition[mobilePosition]} 
        ${open ? style.open : style.close}   `}
      >
        <div className={style.container}>
          <SidenavHeader />
          <SidenavItems />
        </div>
      </aside>
    </div>
  );
}

SideNavigation.propTypes = {
  mobilePosition: PropTypes.string.isRequired,
};
