import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import { useForm } from "react-hook-form";
import HookFormInput from "../Inputs/HookFormInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import HookFormSelect from "../Inputs/HookFormSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Controller } from "react-hook-form";
import styleImg from "./style.module.css";

function CareerForm({ id, onConfirm, onClose, formData, previewId }) {
  const [loading, setLoading] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const styles = {
    label: "text-colors-primary text-base font-semibold",
    inputWrapper: "tablet:w-[45%]",
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      _id: id === "edit_form" ? formData._id : "",

      imagefile: id === "edit_form" ? formData.imagefile : "",
      title: id === "edit_form" ? formData.title : "",
      position: id === "edit_form" ? formData.position : "",
      description: id === "edit_form" ? formData.description : "",
      salary: id === "edit_form" ? formData.salary : "",
      type: id === "edit_form" ? formData.type : "",

      post: id === "edit_form" ? formData.post : 0,

      hrphone: id === "edit_form" ? formData.hrphone : "",
      hremail: id === "edit_form" ? formData.hremail : "",

      isremote: id === "edit_form" ? formData.isremote : false,
      publish: id === "edit_form" ? formData.publish : false,
      summary: id === "edit_form" ? formData.summary : "",
    },
  });

  const descriptionRef = useRef();

  useEffect(() => {
    if (id === "edit_form") {
      const preview = document.getElementById("career-image-preview");

      preview.src = `bucket/motor${formData?.imagefile}`;
    }
  }, [id]);

  const onUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setValue("imagefile", file);
      const url = URL.createObjectURL(file);
      const preview = document.getElementById("career-image-preview");
      preview.src = url;
    }
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const handlePayload = (e) => {
    const watchData = {
      title: watch("title") || formData?.title,
      position: watch("position") || formData?.position,
      description: watch("description") || formData?.description,
      salary: watch("salary") || formData?.salary,
      type: watch("type") || formData?.type,
      post: watch("post") || formData?.post,
      hrphone: watch("hrphone") || formData?.hrphone,
      hremail: watch("hremail") || formData?.hremail,
      isremote: watch("isremote") || formData?.isremote,
      publish: watch("publish") || formData?.publish,
      summary: watch("summary") || formData?.summary,
    };

    if (watch("imagefile") !== formData?.imagefile) {
      watchData.imagefile = watch("imagefile");
    }

    if (id === "edit_form") onConfirm(watchData);
    else onConfirm(e);
  };

  return (
    <div id={id} className="space-y-5 ">
      <p className={`text-orange-600 laptop:px-8 mt-6`}>
        All fields require and please fill all fields!
      </p>

      {/* publish and remote */}
      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={`flex items-center space-x-3 ${styles.inputWrapper}`}>
          <div htmlFor="publish-toggle" className={styles.label}>
            {" "}
            Publish{" "}
          </div>
          <HookFormInput
            type="checkbox"
            placeholder="Publish"
            label="publish"
            validate={{ required: false }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={`flex items-center space-x-3 ${styles.inputWrapper}`}>
          <div className={styles.label}> Is Remote </div>
          <HookFormInput
            type="checkbox"
            placeholder="Is Remote"
            label="isremote"
            validate={{ required: false }}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      {/* title and position */}
      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={`${styles.inputWrapper}`}>
          <div className={styles.label}>Job Title * </div>
          <HookFormInput
            label="title"
            placeholder="Job Title"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={`${styles.inputWrapper}`}>
          <div className={styles.label}>Job Position * </div>
          <HookFormInput
            label="position"
            placeholder="Position"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      {/* type and post */}
      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Job Type (Full-Time/Part-Time) *</div>
          <HookFormSelect
            option={["Full time", "Part time", "Hybrid"]}
            label="type"
            register={register}
            errors={errors}
            validate={{ required: true }}
          />
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Job Post *</div>
          <HookFormInput
            placeholder="Posts"
            label="post"
            validate={{
              required: true,
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: "Please enter valid post number",
              },
            }}
            register={register}
            errors={errors}
          />
          {errors?.post && (
            <p className="mt-2 text-red-500">{errors.post.message}</p>
          )}
        </div>
      </div>

      {/* phone and email */}
      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Phone *</div>
          <HookFormInput
            placeholder="Phone"
            label="hrphone"
            validate={{
              required: true,
              // pattern: {
              //   value: /^(0|[1-9][0-9]*)$/,
              //   message: "Please enter valid phone number",
              // },
            }}
            register={register}
            errors={errors}
          />
          {errors?.hrphone && (
            <p className="mt-2 text-red-500">{errors.hrphone.message}</p>
          )}
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.label}>HR Email *</div>
          <HookFormInput
            placeholder="HR Email"
            label="hremail"
            validate={{
              required: true,
              pattern: {
                value:
                  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email address.",
              },
            }}
            register={register}
            errors={errors}
          />
          {errors?.hremail && (
            <p className="mt-2 text-red-500">{errors.hremail.message}</p>
          )}
        </div>
      </div>

      {/* salary and summary */}
      <div className="w-full space-y-5 laptop:flex laptop:items-start laptop:justify-evenly laptop:space-y-0">
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Salary *</div>
          <HookFormInput
            placeholder="Salary"
            label="salary"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={styles.inputWrapper}>
          {" "}
          <div className={styles.label}>Job Summary *</div>
          <HookFormInput
            type="textarea"
            placeholder="Job Summay"
            label="summary"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      {/* description */}
      <div className="flex w-full flex-col items-center">
        <div className={`${styles.label} w-[95%]`}>Job Description *</div>

        <div className="w-[95%]">
          {editorLoaded ? (
            <Controller
              name="description"
              control={control}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <CKEditor
                  ref={descriptionRef}
                  editor={ClassicEditor}
                  id="rich-text-editor"
                  data={field.value || ""}
                  onReady={(editor) => {
                    editor?.editing?.view?.change((writer) => {
                      writer?.setStyle(
                        "height",
                        "200px",
                        editor?.editing?.view?.document?.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    const data = editor?.getData();
                    field.onChange(data);
                  }}
                />
              )}
            />
          ) : (
            <div>Editor Loading</div>
          )}
          {errors?.description && (
            <p className="mt-2 text-red-500">{errors.description.message}</p>
          )}
        </div>
      </div>

      {/* image upload */}
      <div className="w-full mx-auto">
        <div className="w-[98%] items-center justify-center space-x-3 flex flex-col ">
          <label htmlFor="img_input" className={styleImg.img_label}>
            Upload Career
            <input
              id="img_input"
              className={styleImg.img_input}
              type="file"
              accept="image/*"
              onChange={onUpload}
            />
          </label>
          <p className="text-orange-600">Recommended resolution is 500 x 400</p>

          <div className="min-h-[150px] mt-4  w-full items-center justify-center border border-dashed border-[#000f3c]">
            {loading ? (
              <div className="w-full animate-spin" />
            ) : (
              <div className="flex items-center justify-center">
                <img
                  id="career-image-preview"
                  className="h-[250px] "
                  // src={`/bucket/motor${formData?.imagefile}`}
                  alt="career_image"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* button */}
      <div className="mt-5 flex items-center justify-end space-x-5">
        <PrimaryButton onClicking={onClose}>
          <div className="px-3 py-3">Close</div>
        </PrimaryButton>
        <PrimaryButton
          // onClicking={handleSubmit((data) => {
          //   onConfirm(data);
          // })}
          onClicking={handleSubmit((e) => {
            handlePayload(e);
          })}
          // isDisabled={getValues("imagefile") ? false : true}
          isDisabled={!watch("imagefile")}
        >
          <div className="px-4 py-3">
            {id === "create_form" ? "Create" : "Update"}
          </div>
        </PrimaryButton>
      </div>
    </div>
  );
}

export default CareerForm;
