import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

const HookFormSelect = ({
  setValue,
  register,
  selectedItems,
  option,
  errors,
  label,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSelect = (id) => {
    const updatedItems = selectedItems?.includes(id)
      ? selectedItems
      : [...selectedItems, id];

    setValue(label, updatedItems);
  };

  const handleRemove = (id) => {
    const updatedItems = selectedItems?.filter((item) => item !== id);
    setValue(label, updatedItems);
    setShowDropdown(true);
  };

  return (
    <div className="relative">
      <div
        className="w-full rounded-md border border-theme px-3 py-2 text-theme flex justify-between items-center"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div>
          {selectedItems?.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {selectedItems?.map((id) => {
                const item = option?.find((option) => option === id);
                return (
                  <span
                    key={id}
                    className={`flex items-center bg-blue-100 text-blue-700 px-2 py-1 rounded `}
                  >
                    {item}
                    <button
                      type="button"
                      className="ml-2 text-red-500 hover:text-red-700"
                      onClick={() => handleRemove(id)}
                    >
                      &times;
                    </button>
                  </span>
                );
              })}
            </div>
          ) : (
            <span className="text-gray-400">Select types...</span>
          )}
        </div>

        <div className="absolute top-4 right-4 cursor-pointer">
          <FiChevronDown onClick={() => setShowDropdown(!showDropdown)} />
        </div>
      </div>

      {showDropdown && (
        <div className="absolute z-10 mt-1 w-full rounded-md border border-gray-300 bg-white shadow-lg">
          {option?.map((option, i) => (
            <div
              key={i}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}

      {/* Hidden input for React Hook Form */}
      <input
        type="hidden"
        {...register(label, { required: true })}
        value={selectedItems}
      />

      {selectedItems?.length === 0 &&
        errors.label &&
        errors?.label?.type === "required" && (
          <span className="text-red-500 text-sm mt-2">
            This field is required.
          </span>
        )}
    </div>
  );
};

export default HookFormSelect;
