import axios from "axios";
import { jwtDecode } from "jwt-decode";

const axiosClient = axios.create({
  baseURL: "/",
});

axiosClient.interceptors.request.use(
  async (config) => {
    const access_token = localStorage?.getItem("access_token");
    const decodedToken = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;

    if (access_token && decodedToken.exp > currentTime) {
      config.headers["secret_key"] = access_token;
    } else {
      if (decodedToken?.exp < currentTime) {
        try {
          const response = await axios.get("/backpanel/swap-token", {
            withCredentials: true,
          });

          if (response?.data?.tokens?.accessToken) {
            localStorage.setItem(
              "access_token",
              response?.data?.tokens?.accessToken
            );
            config.headers["secret_key"] = response?.data?.tokens?.accessToken;
          } else {
            throw new Error("Invalid token response");
          }
        } catch (error) {
          console.error("Failed to refresh token:", error);

          return Promise.reject(error);
        }
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
axiosClient?.interceptors?.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("access_token");

      window.location.replace("login");
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
