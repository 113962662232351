import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Controller } from "react-hook-form";
import HookFormInput from "../Inputs/HookFormInput";
import HookFormSelect from "../Inputs/VehicleHookFormSelect";
import DefaultEditionHookFormSelect from "../Inputs/VehicleDefaultHookFormSelect";
import SpecificationField from "../Inputs/VehicleSpecificationFormInput";
import BannerTipsField from "../Inputs/VehicleBannerTips";
import PrimaryButton from "../Buttons/PrimaryButton";
import { v4 as uuidv4 } from "uuid";
import axiosClient from "../../axios-client";
import useSWR from "swr";
import styleImg from "./style.module.css";

const VehicleForm = ({ type, onClose, onConfirm, formData }) => {
  //get edition list
  const fetchEdition = async (url) => {
    try {
      const response = await axiosClient.get(url);
      return response?.data;
    } catch (err) {
      console.error("Error fetching edition lists:", err);
      return undefined;
    }
  };

  const {
    data: editonLists,
    mutate,
    isLoading,
  } = useSWR(`/backpanel/edition-list`, fetchEdition);

  const styles = {
    label: "text-colors-primary text-base font-semibold",
    inputWrapper: "tablet:w-[45%]",
  };

  const [loading, setLoading] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const [newImages, setNewImages] = useState([]);

  const [bannerTipsAddition, setBannerTipsAddition] = useState(
    type === "editForm" && formData?.banner_tips
      ? formData?.banner_tips.map((tip) => ({ id: tip._id }))
      : [{ id: uuidv4() }]
  );

  const [bannerTips, setBannerTips] = useState(
    type === "editForm" && formData?.banner_tips
      ? formData.banner_tips.map((tip) => ({ key: tip.key, value: tip.value }))
      : [{ key: "", value: "" }]
  );

  // banner_tips
  const handleBannerTipsAddition = () => {
    setBannerTips((prev) => [...prev, { key: "", value: "" }]);
  };

  const handleBannerTipsReduceField = (id) => {
    setBannerTips((prev) => prev.filter((_, i) => i !== id));
  };

  const handleBannerTipsChange = (index, field, value) => {
    setBannerTips((prev) =>
      prev.map((spec, i) => (i === index ? { ...spec, [field]: value } : spec))
    );
  };

  const onBannerUpload = async (e) => {
    const fileName = e.target.files[0].name;
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const preview = document.getElementById("vehicle-image-preview");
      preview.src = url;

      setValue("banner_image", fileName);

      setNewImages([...newImages, file]);
    }
  };

  useEffect(() => {
    if (type === "editForm") {
      if (formData?.intro_image) {
        const previewImage = document.getElementById("image-preview");
        const previewVideo = document.getElementById("video-preview");
        previewVideo.style.display = "none";
        previewImage.style.display = "block";
        previewImage.src = `/bucket/motor${formData?.intro_image}`;
      } else if (formData?.videofile) {
        const previewImage = document.getElementById("image-preview");
        const previewVideo = document.getElementById("video-preview");
        previewVideo.style.display = "block";
        previewImage.style.display = "none";
        previewVideo.src = `/bucket/motor${formData?.videofile}`;
        previewVideo.play();
      }
      if (formData?.banner_image) {
        const preview = document.getElementById("vehicle-image-preview");
        preview.src = `/bucket/motor${formData?.banner_image}`;
      }
      if (formData?.test_drive_image) {
        const preview = document.getElementById("test-drive-image-preview");
        preview.src = `/bucket/motor${formData?.test_drive_image}`;
      }
    }
  }, [type]);

  const onFileUpload = async (e) => {
    const fileName = e.target.files[0].name;
    const file = e.target.files[0];

    if (file) {
      const url = URL.createObjectURL(file);
      const previewImage = document.getElementById("image-preview");
      const previewVideo = document.getElementById("video-preview");

      if (file.type.startsWith("image/")) {
        setValue("intro_image", fileName);
        setNewImages([...newImages, file]);

        // Render image
        previewVideo.style.display = "none";
        previewImage.style.display = "block";
        previewImage.src = url;
      } else if (file.type.startsWith("video/")) {
        setValue("videofile", file);

        // Render video
        previewImage.style.display = "none";
        previewVideo.style.display = "block";
        previewVideo.src = url;
        previewVideo.play();
      } else {
        // Handle unsupported file types
        alert("Unsupported file type. Please upload an image or video.");
      }
    }
  };

  const onTestDriveUpload = async (e) => {
    const fileName = e.target.files[0].name;
    const file = e.target.files[0];

    if (file) {
      const url = URL.createObjectURL(file);
      const preview = document.getElementById("test-drive-image-preview");
      preview.src = url;
    }

    setValue("test_drive_image", fileName);
    setNewImages([...newImages, file]);
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      order: type === "editForm" ? formData?.order : "",
      publish: type === "editForm" ? formData?.publish : false,
      banner_image: type === "editForm" ? formData?.banner_image : "",
      title: type === "editForm" ? formData?.banner_title : "",
      subtitle: type === "editForm" ? formData?.banner_subtitle : "",
      test_drive_image: type === "editForm" ? formData?.test_drive_image : "",
      default_edition: type === "editForm" ? formData?.default_edition : "",

      other_edition:
        type === "editForm"
          ? formData?.other_edition?.map((edition, i) => edition._id)
          : [],

      name: type === "editForm" ? formData?.vehicle_name : "",
      is_manual: type === "editForm" ? formData?.is_manual : false,
      videofile: type === "editForm" ? formData?.videofile : "",
      intro_image: type === "editForm" ? formData?.intro_image : "",
    },
  });

  //multiple select for default edition and other edition
  //other edition is editons except default edition
  let selectedDefaultEdition;

  selectedDefaultEdition = watch("default_edition", "");

  let otherEditionLists;
  otherEditionLists = editonLists?.filter(
    (list) => list._id !== selectedDefaultEdition
  );

  let selectedEditions;

  selectedEditions = watch("other_edition", []);

  if (watch("default_edition") !== formData.default_edition) {
    selectedEditions = selectedEditions?.filter(
      (list) => list !== watch("default_edition")
    );
  }

  const handlePayload = (e) => {
    const watchData = {
      publish: watch("publish") || formData?.publish,
      order: watch("order") || formData?.order,
      title: watch("title") || formData?.banner_title,
      subtitle: watch("subtitle") || formData?.banner_subtitle,
      default_edition:
        watch("default_edition") || formData?.default_edition?._id,

      other_edition: selectedEditions,
    };
    if (watch("banner_image") !== formData.banner_image) {
      watchData.banner_image = watch("banner_image");
    }
    if (watch("test_drive_image") !== formData.test_drive_image) {
      watchData.test_drive_image = watch("test_drive_image");
    }
    if (watch("videofile") !== formData.videofile) {
      watchData.videofile = watch("videofile");
    }
    if (watch("intro_image") !== formData.intro_image) {
      watchData.intro_image = watch("intro_image");
    }

    if (type === "editForm") onConfirm(watchData, newImages, bannerTips);
    else onConfirm(e, newImages, bannerTips);
  };

  let haveIntroImage = watch("intro_image") || watch("videofile");

  return (
    <div className="space-y-5">
      {/* publish & order */}
      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={`${styles.inputWrapper}`}>
          <div className="flex items-center space-x-3">
            <div className={styles.label} htmlFor="publish">
              {" "}
              Publish{" "}
            </div>
            <HookFormInput
              type="checkbox"
              placeholder="publish"
              label="publish"
              validate={{ required: false }}
              register={register}
              errors={errors}
            />
          </div>
        </div>
        <div className={`${styles.inputWrapper}`}>
          <div className="flex items-center space-x-3">
            <div className={styles.label} htmlFor="order">
              Order *
            </div>
            <HookFormInput
              placeholder="order"
              label="order"
              validate={{ required: true }}
              register={register}
              errors={errors}
            />
          </div>
        </div>
      </div>

      {/* default and other edition */}
      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Vehicle Default Edition *</div>
          <DefaultEditionHookFormSelect
            name="edition"
            option={editonLists}
            label="default_edition"
            register={register}
            errors={errors}
            validate={{ required: true }}
          />
        </div>

        <div className={styles.inputWrapper}>
          <div className={styles.label}>Vehicle Other Edition *</div>
          <HookFormSelect
            setValue={setValue}
            option={otherEditionLists}
            label="other_edition"
            // register={register}
            errors={errors}
            selectedItems={selectedEditions}
          />
        </div>
      </div>

      {/* banner title and subtitle */}
      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={`${styles.inputWrapper}`}>
          <div className={styles.label}>Banner Title * </div>
          <HookFormInput
            label="title"
            placeholder="Banner Title"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={`${styles.inputWrapper}`}>
          <div className={styles.label}>Banner Subtitle * </div>
          <HookFormInput
            label="subtitle"
            placeholder="Banner Subtitle"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      {/* banner tips */}
      <div className="w-full space-y-5 laptop:flex laptop:flex-col laptop:items-center laptop:justify-evenly laptop:space-y-2 my-6">
        <div className="w-[93%] flex items-center justify-center gap-6 ">
          <div className={`${styles.label}  `}>Banner Tips * </div>
          <button
            className={`w-6 h-6 rounded-full shadow-md flex items-center justify-center text-center bg-[#2962ff] text-white  `}
            onClick={() => handleBannerTipsAddition()}
          >
            <span className="text-[18px] font-bold">+</span>
          </button>
        </div>

        <div className={`flex flex-col gap-3 w-[93%] `}>
          {bannerTips?.map((field, i) => (
            <BannerTipsField
              key={i}
              index={i}
              field={field}
              onChange={handleBannerTipsChange}
              onRemove={() => handleBannerTipsReduceField(i)}
              showRemoveButton={true}
            />
          ))}
        </div>
      </div>

      {/* banner upload */}
      <div className="w-full flex items-center justify-center">
        <div className="w-[95%] items-center justify-center space-x-3 flex flex-col ">
          <label htmlFor="img_input" className={styleImg.img_label}>
            Upload Banner
            <input
              id="img_input"
              className={styleImg.img_input}
              type="file"
              accept="image/*"
              onChange={onBannerUpload}
            />
          </label>
          <p className="text-orange-600">
            Recommended resolution is 1440 x 573
          </p>

          <div className="min-h-[200px] mt-4  w-full items-center justify-center border border-dashed border-[#000f3c]">
            {loading ? (
              <div className="w-full animate-spin" />
            ) : (
              <div className="flex items-center justify-center">
                <img id="vehicle-image-preview" alt="vehicle_banner_image" />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* vehicle file upload */}
      <div className="w-full flex items-center justify-center">
        <div className="w-[95%] items-center justify-center space-x-3 flex flex-col ">
          <label
            htmlFor="vehicle_img_input"
            className={styleImg.vehicle_img_label}
          >
            Upload Vehicle
            <input
              id="vehicle_img_input"
              className={styleImg.vehicle_img_input}
              type="file"
              // accept="image/*"
              onChange={onFileUpload}
            />
          </label>
          <p className="text-orange-600">
            Recommended resolution is 1440 x 573
          </p>

          <div className="min-h-[200px] mt-4  w-full items-center justify-center border border-dashed border-[#000f3c]">
            {loading ? (
              <div className="w-full animate-spin" />
            ) : (
              <div className="flex items-center justify-center">
                <img
                  id="image-preview"
                  style={{ display: "none" }}
                  alt="Preview Image"
                />
                <video
                  id="video-preview"
                  style={{ display: "none" }}
                  controls
                ></video>{" "}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* test drive upload*/}
      <div className="w-full flex items-center justify-center">
        <div className="w-[95%] items-center justify-center space-x-3 flex flex-col ">
          <label
            htmlFor="testDrive_img_input"
            className={styleImg.testDrive_img_label}
          >
            Upload Test Drive
            <input
              id="testDrive_img_input"
              className={styleImg.testDrive_img_input}
              type="file"
              accept="image/*"
              onChange={onTestDriveUpload}
            />
          </label>
          <p className="text-orange-600">
            Recommended resolution is 1440 x 573
          </p>

          <div className="min-h-[200px] mt-4  w-full items-center justify-center border border-dashed border-[#000f3c]">
            {loading ? (
              <div className="w-full animate-spin" />
            ) : (
              <div className="flex items-center justify-center">
                <img id="test-drive-image-preview" alt="test_drive_image" />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* button */}

      <div className="flex items-center justify-end space-x-5">
        <PrimaryButton onClicking={onClose}>
          <div className="px-4 py-3">Cancel</div>
        </PrimaryButton>

        <PrimaryButton
          onClicking={handleSubmit((e) => {
            handlePayload(e);
          })}
          isDisabled={
            !watch("banner_image") ||
            !haveIntroImage ||
            !watch("test_drive_image") ||
            !bannerTips?.[0]?.key ||
            !bannerTips?.[0]?.value
          }
        >
          <div className="px-4 py-3">
            {type === "createForm" ? "Create" : "Update"}
          </div>
        </PrimaryButton>
      </div>
    </div>
  );
};

export default VehicleForm;
