import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Overlay from "../dashboard/provider/overlay";
import TopNavigation from "../dashboard/topnavigation";
import DashboardProvider from "../dashboard/provider/context";
import SideNavigation from "../dashboard/sidenavigation";
import useAuth from "../../src/Utils/useAuth";
import NotAllow from "../components/NotAllow";
import { ToastContainer } from "react-toastify";

function DashboardLayout() {
  const isAuth = useAuth();

  // if (!isAuthorized) {
  //   return <NotAllow />;
  // }

  if (!isAuth) {
    return <Navigate to={"/login"} />;
  }

  return (
    <DashboardProvider>
      <div className="relative h-screen overflow-hidden">
        <div className="flex items-start">
          <Overlay />

          <SideNavigation mobilePosition="right" />
          <div className="flex h-screen w-full flex-col bg-[#f5f5f5] pl-0 laptop:w-[100vw]">
            <TopNavigation />
            <main className="h-screen overflow-auto bg-[#fff] px-2 pb-36 pt-4 tablet:px-4 tablet:pb-8 laptop:rounded-tl-3xl laptop:px-6">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </DashboardProvider>
  );
}

export default DashboardLayout;
