import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import LoadingComponent from "../Loading";
import BottomLoading from "./LoadingComponents/BottomLoading";
import TopLoading from "./LoadingComponents/TopLoading";
import styles from "./style.module.css";

function MainLoading({ enter, bg = "#000F3C" }) {
  const nodeRef1 = useRef();
  return (
    <>
      <TopLoading bg={bg} enter={enter} />
      <BottomLoading bg={bg} enter={enter} />
      <CSSTransition
        classNames={{
          enterActive: styles.loaderEnterActive,
          enterDone: styles.loaderEnterDone,
          exitActive: styles.loaderExitActive,
          exitDone: styles.loaderExitDone,
        }}
        nodeRef={nodeRef1}
        in={enter}
        mountOnEnter
        unmountOnExit
        timeout={500}
      >
        <div>
          <div
            ref={nodeRef1}
            className="fixed w-full h-full flex flex-col justify-center items-center top-0 left-0 z-50"
          >
            <img
              src="/images/Motor_Logo.svg"
              width={150}
              height={75}
              alt="AGB-logo"
            />
            <LoadingComponent themeColor="#fff" />
          </div>
        </div>
      </CSSTransition>
    </>
  );
}

export default MainLoading;

MainLoading.propTypes = {
  bg: PropTypes.string,
  enter: PropTypes.bool.isRequired,
};

MainLoading.defaultProps = {
  bg: "#000F3C",
};
