import React from "react";
import PropTypes from "prop-types";

function PrimaryInput({
  type = "",
  id = "",
  name = "",
  inputRef,
  focus = false,
  value = "",
  placeHolderText = "",
  isRequired = false,
  isDisabled = false,
  icon,
  errorMessage = "",
  /**
   * action
   */
  handleChangeOnInput,
  handleClickOnIcon,
  keyDownHandler,
}) {
  return (
    <div className="relative w-full h-auto">
      <input
        type={type}
        id={id}
        name={name}
        ref={inputRef}
        autoFocus={focus}
        value={value}
        className={`body-font placeholder:caption-font text-[#000] w-full h-auto bg-transparent border border-opacity-70 border-gray-400 py-3 pl-5 pr-12 rounded-full  focus:outline-[#f5f5f5] ${
          errorMessage
            ? "focus:border-danger focus:ring-danger"
            : "focus:border-current focus:ring-current"
        } focus:rng-1 transition
        ease-in-out`}
        placeholder={placeHolderText}
        required={isRequired}
        disabled={isDisabled}
        onKeyDown={(e) => keyDownHandler(e)}
        /**
         * action
         */
        onChange={(e) => handleChangeOnInput(e)}
      />
      {icon && (
        <button
          type="button"
          className="absolute top-4 right-5 laptop:cursor-pointer"
          onClick={handleClickOnIcon}
        >
          {icon}
        </button>
      )}
      {errorMessage && (
        <p className="caption-font text-danger ml-6 mt-1"> {errorMessage} </p>
      )}
    </div>
  );
}

PrimaryInput.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
  focus: PropTypes.bool,
  value: PropTypes.string,
  placeHolderText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  icon: PropTypes.node,
  errorMessage: PropTypes.string,
  handleChangeOnInput: PropTypes.func,
  handleClickOnIcon: PropTypes.func,
  keyDownHandler: PropTypes.func,
};

export default PrimaryInput;
