import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import HookFormInput from "../Inputs/HookFormInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import styles from "./style.module.css";
import PreviewFile from "../PreviewFile";

function SpecificationFileForm({ type, onConfirm, onClose, formData }) {
  console.log("formData:::", formData);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(
    type === "editForm" ? formData?.pdffile : null
  );
  const [removePdf, setRemovePdf] = useState([]);
  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm({
    defaultValues: {
      name: type === "editForm" ? formData?.name : "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const handleRemovePdf = (url) => {
    setRemovePdf((prev) => [...prev, url]);
  };
  return (
    <div className="px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
        {/* name */}
        <div className="w-1/2 space-y-5 laptop:space-y-0">
          <div className={styles.inputWrapper}>
            <div className={styles.label}>PDF Name *</div>
            <HookFormInput
              placeholder="PDF Name"
              label="name"
              validate={{ required: true }}
              register={register}
              errors={errors}
            />
          </div>
        </div>

        {/* pdf upload preview*/}
        <PreviewFile
          pdfFile={pdfFile}
          setPdfFile={setPdfFile}
          previewURL={previewURL}
          setPreviewURL={setPreviewURL}
          onRemove={handleRemovePdf}
        />

        {/* buttons */}
        <div className="flex items-center justify-end space-x-5">
          <PrimaryButton onClicking={onClose}>
            <div className="px-4 py-3">Cancel</div>
          </PrimaryButton>
          <PrimaryButton
            onClicking={handleSubmit((data) => {
              onConfirm(data, pdfFile, removePdf);
            })}
            isDisabled={previewURL === null}
          >
            <div className="px-4 py-3">
              {type === "createForm" ? "Create" : "Update"}
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default SpecificationFileForm;
