import React, { useContext, useEffect, useMemo, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import useSWR from "swr";
import CustomizeManualCell from "../../components/Table/Components/CustomizeManualCell";
import useSWRMutation from "swr/mutation";

import ActionComponent from "../../components/Table/Components/ActionComponent";

import CustomAlert from "../../components/Notification/CustomAlert";

import LoadingComponent from "../../components/Loading";

import CustomizedTable from "../../components/Table/CustomizedTable";

import FormModal from "../../components/FormModal";

import MainLoading from "../../components/MainLoading";
import "react-toastify/dist/ReactToastify.css";
import CustomizedImageCell from "../../components/Table/Components/CustomizedImageCell";
import axiosClient from "../../axios-client";
import { ErrContext } from "../../providers/ErrorProvider";
import EditionForm from "../../components/Forms/EditionForm";
import Header from "../../components/PageHeader";

export default function AddCar() {
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [isShowCreate, setIsShowCreate] = useState(false);

  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,

    message: "",

    type: "",

    btnLabel: "",

    btnColor: "",

    onBtnClick: null,
  });

  const defaultData = {
    _id: "",
    publish: false,
    name: "",
    type: "",
    imagefile: [],
    position: [],
    address: "",
    phone: "",
  };

  const [formData, setFormData] = useState(defaultData);
  const [setIsError, setErrMsg] = useContext(ErrContext);

  // get all edition
  const fetchData = async ([pageParams, limitParams]) => {
    let resp;
    resp = await axiosClient
      .get(`/backpanel/edition?page=${pageParams}&limit=${limitParams}`)
      .then((response) => response?.data)
      .catch((err) => {
        setIsError(true);
        setErrMsg({ code: err.response, msg: err.message });
      });

    return resp;
  };

  const {
    data: editions,
    mutate,
    isLoading,
  } = useSWR([page, limit], fetchData);

  const { trigger, isMutating } = useSWRMutation([page, limit], fetchData);

  useEffect(() => {
    trigger({ ...editions, page, limit });

    mutate();
  }, []);

  //add edition
  const createEdition = async (body) => {
    const resp = await axiosClient
      .post(`/backpanel/edition`, body)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });
        setIsShowCreate(false);
        mutate();
        return response;
      })
      .catch((err) => {
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });

    return resp;
  };

  const submitEdition = async (data, type, removeImages, specifications) => {
    if (type === "create") {
      const formData = new FormData();

      if (data?.imagefile?.length != 0) {
        for (let i = 0; i < data?.imagefile?.length; i++) {
          formData.append("imagefile", data?.imagefile[i]);
        }
      }

      formData.append("edition_name", data?.edition_name);

      formData.append("model_id", data?.model_id);

      formData.append(
        "edition_description",
        JSON.stringify(data?.edition_description)
      );

      formData.append("color", JSON.stringify(data?.color));

      formData.append("interior", JSON.stringify(data?.interior));

      formData.append("exterior", JSON.stringify(data?.exterior));
      formData.append("is_manual", data?.is_manual);
      formData.append("rate", data?.rate);

      formData.append("type", data?.type);
      formData.append("specification", JSON.stringify(specifications));
      formData.append("specification_file", data?.specification_file);
      formData.append("demo_images", JSON.stringify(data?.demoImages));

      const resp = await createEdition(formData);
    } else if (type === "edit") {
      const fData = new FormData();
      if (data?.imagefile?.length != 0) {
        for (let i = 0; i < data?.imagefile?.length; i++) {
          fData.append("imagefile", data?.imagefile[i]);
        }
      }
      fData.append("edition_name", data?.edition_name);

      fData.append("model_id", data?.model_id);

      fData.append(
        "edition_description",
        JSON.stringify(data?.edition_description)
      );

      fData.append("color", JSON.stringify(data?.color));

      fData.append("interior", JSON.stringify(data?.interior));

      fData.append("exterior", JSON.stringify(data?.exterior));
      fData.append("is_manual", data?.is_manual);
      fData.append("rate", data?.rate);

      fData.append("type", data?.type);
      fData.append("specification", JSON.stringify(specifications));
      fData.append("specification_file", data?.specification_file);
      fData.append("demo_images", JSON.stringify(data?.demoImages));
      fData.append(
        "removeImage",
        removeImages ? JSON.stringify(removeImages) : JSON.stringify([])
      );

      updateEdition(fData);
    }
  };

  const editData = async (row) => {
    const tableData = { ...row };

    setFormData(tableData);
    setIsOpenEdit(true);
  };

  const deleteData = async (row) => {
    setAlertState({
      ...alertState,

      open: false,
    });

    await axiosClient
      .delete(`/backpanel/edition/${row?._id}`)

      .then((response) => {
        toast(response?.data?.message || "successfully deleted", {
          type: "success",
        });
      })

      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    mutate();
  };

  const updateEdition = async (body) => {
    const resp = await axiosClient
      .put(`/backpanel/edition/${formData?._id}`, body)

      .then(async (response) => {
        await mutate();

        setIsOpenEdit(false);

        toast(response.data.message, { type: "success" });

        return response;
      })

      .catch((err) => {
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });

    // setFormData({
    //   ...formData,
    // });

    return resp;
  };

  const columns = useMemo(() => {
    if (!editions) return [];

    const columnData = [
      {
        Header: "Action",

        accessor: "action",

        filter: false,

        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),

        width: 100,
      },

      ...editions?.columns,
    ];

    const changeCol = columnData.map((col) => {
      const column = col;

      if (column.accessor?.includes("imagefile")) {
        column.Cell = ({ cell }) => CustomizedImageCell({ cell });

        column.width = 200;
      }

      if (column.accessor?.includes("brand_logo")) {
        column.Cell = ({ cell }) => CustomizedImageCell({ cell });

        column.width = 200;
      }
      if (column.accessor === "is_manual") {
        column.Cell = ({ cell }) => CustomizeManualCell({ cell });

        column.width = 50;
      }

      return column;
    });

    return changeCol;
  }, [editions]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;

      tempColumn.show = true;

      return tempColumn;
    });

    return global;
  }, [columns]);

  if (isLoading || !editions || isMutating) {
    return <MainLoading enter={isLoading || isMutating} bg="#000F3C4D" />;
  }

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isMutating} />

      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>
      <Header pageName="Add Car" />
      <CustomizedTable
        dataRows={editions?.data}
        dataColumns={columns}
        totalItems={editions.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        loading={isLoading}
        onCreate={() => setIsShowCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...editions, page, limit })}
        colWithShow={globalColumn}
      />

      <FormModal
        header="Create Edition"
        isOpen={isShowCreate}
        title="Create Edition"
      >
        <EditionForm
          type="createForm"
          formData={formData}
          onClose={() => setIsShowCreate(false)}
          onConfirm={(data, removeImages, specifications) =>
            submitEdition(data, "create", null, specifications)
          }
        />
      </FormModal>

      <FormModal isOpen={isOpenEdit} header="Edit Edition">
        <EditionForm
          type="editForm"
          formData={formData}
          onClose={() => {
            setFormData({
              ...formData,
            });

            setIsOpenEdit(false);
          }}
          onConfirm={(data, removeImages, specifications) =>
            submitEdition(data, "edit", removeImages, specifications)
          }
        />
      </FormModal>
      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      />
    </>
  );
}
