import React from "react";
import PropTypes from "prop-types";

function CustomizeDateCell({ cell }) {
  return <div> {new Date(cell.value).toLocaleDateString()} </div>;
}
CustomizeDateCell.propTypes = {
  cell: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.bool])
  ).isRequired,
};

export default CustomizeDateCell;
