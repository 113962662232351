/* eslint-disable no-underscore-dangle */
import _ from "lodash";
import { ErrContext } from "../../../src/providers/ErrorProvider";
import React, { useMemo, useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import CustomizedTable from "../../../src/components/Table/CustomizedTable";
import FormModal from "../../../src/components/FormModal";

import CareerForm from "../../components/Forms/CareerForm";
import LoadingComponent from "../../../src/components/Loading";
import CustomAlert from "../../../src/components/Notification/CustomAlert";
import MainLoading from "../../../src/components/MainLoading/index";
import "react-toastify/dist/ReactToastify.css";
import ActionComponent from "../../../src/components/Table/Components/ActionComponent";
import CustomizePublishCell from "../../../src/components/Table/Components/CustomizePublishCell";
import CustomizedImageCell from "../../../src/components/Table/Components/CustomizedImageCell";
import CustomizeIsremoteCell from "../../../src/components/Table/Components/CustomizeIsremoteCell";
import axiosClient from "../../axios-client";
import Header from "../../components/PageHeader";

export default function CarrerPage() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    type: "",
    btnLabel: "",
    btnColor: "",
    onBtnClick: null,
  });

  const defaultData = {
    imagefile: "",
    title: "",
    position: "",
    description: "",
    summary: "",
    post: 0,
    salary: "",
    type: "",
    isremote: false,
    hremail: "",
    hrphone: "",
    publish: false,
  };

  const [formData, setFormData] = useState(defaultData);
  const [setIsError, setErrMsg] = useContext(ErrContext);

  const fetchData = async ([pageParams, limitParams]) => {
    let resp;

    resp = await axiosClient
      .get(`/backpanel/career?page=${pageParams}&limit=${limitParams}`)
      .then((response) => response.data)
      .catch((err) => {
        setIsError(true);
        setErrMsg({ code: err?.response?.status, msg: err?.message });
      });

    return resp;
  };

  const { data: career, mutate, isLoading } = useSWR([page, limit], fetchData);
  const { trigger, isMutating } = useSWRMutation([page, limit], fetchData);

  useEffect(() => {
    trigger({ ...career, page, limit });

    mutate();
  }, []);

  const addCareer = async (body) => {
    const formData = new FormData();

    formData.append("salary", body.salary);
    formData.append("imagefile", body.imagefile);

    formData.append("isremote", body.isremote);
    formData.append("description", body.description);

    formData.append("hrphone", body.hrphone);
    formData.append("position", body.position);

    formData.append("post", body.post);
    formData.append("publish", body.publish);
    formData.append("hremail", body.hremail);
    formData.append("title", body.title);
    formData.append("type", body.type);
    formData.append("summary", body.summary);

    await axiosClient
      .post(`/backpanel/career`, formData)
      .then(async (res) => {
        await mutate();
        if (res?.status !== 200) {
          setIsOpenCreate(true);
          toast(res?.response?.data?.message, { type: "error" });
        } else {
          setIsOpenCreate(false);
          toast(res?.data?.message, { type: "success" });
        }
      })
      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    return null;
  };

  const editData = async (row) => {
    const tableData = {
      _id: row._id,
      imagefile: row.imagefile,
      title: row.title,
      position: row.position,
      description: row.description,

      post: row.post,
      salary: row.salary,

      type: row.type,
      isremote: row.isremote,

      hremail: row.hremail,

      hrphone: row.hrphone,
      publish: row.publish,
      summary: row.summary,
    };
    setFormData(tableData);
    setIsOpenEdit(true);
  };
  let removeImage = [];
  const updateCareer = async (body, id) => {
    const data = new FormData();

    data.append("title", body.title);
    data.append("position", body.position);
    data.append("description", body.description);
    data.append("summary", body.summary);

    data.append("post", body?.post);
    data.append("salary", body?.salary);

    data.append("type", body?.type);
    data.append("isremote", body?.isremote);

    data.append("hremail", body?.hremail);

    data.append("hrphone", body?.hrphone);
    data.append("publish", body?.publish);

    if (body.imagefile) {
      data.append("imagefile", body.imagefile);
      removeImage.push(formData.imagefile);
    }

    data.append(
      "removeImage",
      removeImage ? JSON.stringify(removeImage) : JSON.stringify([])
    );

    await axiosClient
      .put(`/backpanel/career/${id}`, data)
      .then(async (res) => {
        await mutate();
        if (res?.status !== 200) {
          setIsOpenEdit(true);

          toast(res?.response?.data?.message, { type: "error" });
        } else {
          setIsOpenEdit(false);
          toast(res?.data?.message, { type: "success" });
          setFormData({
            ...defaultData,
          });
        }
      })
      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
  };

  const deleteData = async (row) => {
    setAlertState({
      ...alertState,
      open: false,
    });
    await axiosClient
      .delete(`/backpanel/career/${row._id}`)
      .then((res) => {
        toast(res?.data?.message, { type: "success" });
      })
      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    mutate();
    // await trigger({ ...career, page, limit });
    // await mutate();
  };

  const columns = useMemo(() => {
    if (!career) return [];
    const columnData = [
      {
        Header: "Action",
        accessor: "action",
        filter: false,
        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),
        width: 100,
      },
      ...career.columns,
    ];
    const changeCol = columnData.map((col) => {
      const column = col;

      if (column.accessor === "publish") {
        column.Cell = ({ cell }) => CustomizePublishCell({ cell });
      }
      if (column.accessor?.includes("imagefile")) {
        column.Cell = ({ cell }) => CustomizedImageCell({ cell });

        column.width = 200;
      }
      if (column.accessor === "isremote") {
        column.Cell = ({ cell }) => CustomizeIsremoteCell({ cell });
      }

      return column;
    });
    return changeCol;
  }, [career]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;
      tempColumn.show = true;
      return tempColumn;
    });
    return global;
  }, [columns]);

  if (isLoading || !career || isMutating) {
    return <MainLoading enter={isLoading || isMutating} bg="#000F3C4D" />;
  }

  return (
    <>
      <Header pageName="Career" />
      <CustomizedTable
        dataRows={career?.data}
        dataColumns={columns}
        totalItems={career?.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        loading={isLoading}
        onCreate={() => setIsOpenCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...career, page, limit })}
        colWithShow={globalColumn}
      />
      <ToastContainer autoClose={3000} />
      <FormModal isOpen={isOpenCreate} header="Create Career">
        <CareerForm
          id="create_form"
          previewId="previewDialog"
          onClose={() => {
            setIsOpenCreate(false);
          }}
          onConfirm={(formdata) => addCareer(formdata)}
        />
      </FormModal>
      <FormModal
        isOpen={isOpenEdit}
        onClose={() => {
          setIsOpenEdit(false);
        }}
        header="Edit Career"
      >
        <CareerForm
          id="edit_form"
          formData={formData}
          onClose={() => {
            setFormData({
              ...defaultData,
            });
            setIsOpenEdit(false);
          }}
          onConfirm={(data) => updateCareer(data, formData?._id)}
        />
      </FormModal>
      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      />
    </>
  );
}
