import React, { useEffect } from "react";
import axiosClient from "../../axios-client";
import useSWR from "swr";
import BannerComponent from "../../components/BannerComponent";
import MainLoading from "../../components/MainLoading";
import useSWRMutation from "swr/mutation";
import Header from "../../components/PageHeader";

const Banner = () => {
  const fetcher = async (url) => {
    try {
      const response = await axiosClient.get(url);
      return response?.data?.banners;
    } catch (err) {
      console.error("Error fetching banners:", err);
      return undefined;
    }
  };

  const {
    data: banners,
    mutate,
    isLoading,
  } = useSWR(`/backpanel/banners`, fetcher, { revalidateOnFocus: false });

  const { trigger, isMutating } = useSWRMutation(`/backpanel/banners`, fetcher);

  if (isLoading) return <MainLoading enter={isLoading} />;

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isLoading || isMutating} />
      <Header pageName="Banner" />
      <div className="flex justify-center">
        {banners ? (
          <BannerComponent
            bannerData={banners}
            refresh={() => trigger(`/backpanel/banners`)}
          />
        ) : (
          <div>No banners found.</div>
        )}
      </div>
    </>
  );
};

export default Banner;
