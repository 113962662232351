import { BiExpand, BiReset } from "react-icons/bi";
import { GiSave } from "react-icons/gi";
import { BsPlusCircle } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PropsTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";

import DealerForm from "../Forms/DealerForm";
import FormModal from "../FormModal";
import styles from "../BannerComponent/style.module.css";
import PrimaryButton from "../Buttons/PrimaryButton";
import CustomAlert from "../Notification/CustomAlert";
import axiosClient from "../../axios-client";

function DealerComponent({ dealerData, refresh }) {
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [updateDetail, setUpdateDetail] = useState({
    show: false,
    detail: null,
  });
  const [showDelAlert, setShowDelAlert] = useState({ id: "", show: false });
  const [data, setData] = useState(dealerData);

  useEffect(() => {
    setData(dealerData);
  }, [dealerData]);

  const createDealer = async (body) => {
    try {
      const response = await axiosClient.post(`/backpanel/dealer`, body);
      toast(response.data.message, { type: "success" });
      await refresh();
      return response;
    } catch (err) {
      toast(err?.response?.data?.message, {
        type: "error",
      });
      return undefined;
    }
  };

  const updateDealer = async (body) => {
    try {
      const response = await axiosClient.put(
        `/backpanel/dealer/${updateDetail.detail._id}`,
        body
      );
      toast(response.data.message, { type: "success" });
      await refresh();
      if (response) {
        setUpdateDetail({ ...updateDetail, show: false });
      }
      return response;
    } catch (err) {
      toast(err?.response?.data?.message || "Something went wrong", {
        type: "error",
      });
      return undefined;
    }
  };

  const removeDealer = async (id) => {
    const resp = await axiosClient
      .delete(`/backpanel/dealer/${id}`)
      .then((response) => {
        toast(response.data.message, { type: "success" });
        refresh();
        return response;
      })
      .catch((err) =>
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        })
      );
    return resp;
  };

  const onSubmitDealer = async (
    newData,
    type,
    newImages,
    removeImages,
    fields
  ) => {
    if (type === "create") {
      const formData = new FormData();
      formData.append("name", newData.name);
      for (let i = 0; i < newImages.length; i++) {
        formData.append("imagefile", newImages[i]);
      }
      formData.append("publish", newData.publish);
      formData.append("address", newData.address);
      formData.append("phone", newData.phone);
      formData.append("position", JSON.stringify([newData.lat, newData.lang]));
      if (fields?.[0].type === "facebook" && fields?.[0].link !== "") {
        formData.append("facebook", fields?.[0].link);
      }
      if (fields?.[1].type === "youtube" && fields?.[1].link !== "") {
        formData.append("youtube", fields?.[1].link);
      }
      if (fields?.[2].type === "telegram" && fields?.[2].link !== "") {
        formData.append("viber", fields?.[2].link);
      }
      if (fields?.[3].type === "linkedin" && fields?.[3].link !== "") {
        formData.append("linkedin", fields?.[3].link);
      }
      if (fields?.[4].type === "instagram" && fields?.[4].link !== "") {
        formData.append("instagram", fields?.[4].link);
      }

      const resp = await createDealer(formData);
      if (resp) {
        setIsShowCreate(false);
      }
    } else {
      const formData = new FormData();
      formData.append("name", newData.name);
      for (let i = 0; i < newImages.length; i++) {
        formData.append("imagefile", newImages[i]);
      }
      formData.append("publish", newData.publish);
      formData.append("address", newData.address);
      formData.append("phone", newData.phone);
      formData.append("position", JSON.stringify([newData.lat, newData.lang]));
      if (fields?.[0].type === "facebook" && fields?.[0].link !== "") {
        formData.append("facebook", fields?.[0].link);
      }
      if (fields?.[1].type === "youtube" && fields?.[1].link !== "") {
        formData.append("youtube", fields?.[1].link);
      }
      if (fields?.[2].type === "telegram" && fields?.[2].link !== "") {
        formData.append("viber", fields?.[2].link);
      }
      if (fields?.[3].type === "linkedin" && fields?.[3].link !== "") {
        formData.append("linkedin", fields?.[3].link);
      }
      if (fields?.[4].type === "instagram" && fields?.[4].link !== "") {
        formData.append("instagram", fields?.[4].link);
      }
      formData.append(
        "removeImage",
        removeImages ? JSON.stringify(removeImages) : JSON.stringify([])
      );

      const resp = await updateDealer(formData);
      if (resp) {
        setIsShowCreate(false);
      }
    }
  };

  const handleDragEnd = (e) => {
    if (e.destination) {
      const tempData = Array.from(data);
      const [sourceData] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, sourceData);
      setData(tempData);
    }
  };

  const ToggleDetail = (index) => {
    const detailList = document.getElementById(`CoverDetail${index}`);
    const detailBtn = document.getElementById(`CoverShowBtn${index}`);
    if (detailList.style.display === "none") {
      detailList.style.display = "contents";
      detailBtn.style.transform = "rotate(180deg)";
      detailBtn.style.transition = "transform 300ms ease-in-out";
    } else {
      detailList.style.display = "none";
      detailBtn.style.transform = "rotate(0deg)";
    }
  };

  const togggleAll = () => {
    data.map((value, index) => ToggleDetail(index));
  };

  const isDifferentSerial = useMemo(() => {
    const dealerString = JSON.stringify(dealerData);
    const dataString = JSON.stringify(data);

    if (dealerString === dataString) {
      return true;
    }
    return false;
  }, [data]);

  const resetDealers = () => {
    setData(dealerData);
  };

  const deleteDealer = (dealerId) => {
    setShowDelAlert({
      id: dealerId,
      show: true,
    });
  };

  const conFirmDelete = async () => {
    const response = await removeDealer(showDelAlert.id);
    if (response) {
      setShowDelAlert({
        id: "",
        show: false,
      });

      await refresh();
    }
  };

  const saveSortedeOrder = async () => {
    const orderKey = data.map((value) => value._id);
    const keyString = orderKey.join(",");
    await axiosClient
      .get(`/backpanel/dealers-sort?keys=${keyString}`)
      .then((resp) => {
        toast(resp?.data?.message, { type: "success" });
        refresh();
        return resp;
      })
      .catch((err) =>
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        })
      );
  };

  const editDealer = (bData) => {
    setUpdateDetail({
      show: true,
      detail: bData,
    });
  };

  return (
    <>
      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>
      <CustomAlert
        btnColor="red"
        message="Are you sure want to delete!!!"
        btnLabel="Delete"
        open={showDelAlert.show}
        onClose={() => setShowDelAlert({ ...showDelAlert, show: false })}
        onBtnClick={() => conFirmDelete()}
        type="warn"
      />

      <FormModal
        header="Create Dealer"
        isOpen={isShowCreate}
        title="Create Dealer"
      >
        <DealerForm
          type="createForm"
          previewId="previewDialog"
          onClose={() => setIsShowCreate(false)}
          onConfirm={(data, newImages, removeImages, fields) =>
            onSubmitDealer(data, "create", newImages, null, fields)
          }
        />
      </FormModal>

      <FormModal isOpen={updateDetail.show} header="Edit Dealer">
        <DealerForm
          type="editForm"
          formData={updateDetail?.detail}
          onClose={() => setUpdateDetail({ ...updateDetail, show: false })}
          onConfirm={(data, newImages, removeImages, fields) =>
            onSubmitDealer(data, "edit", newImages, removeImages, fields)
          }
        />
      </FormModal>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="flex flex-col">
          <div className="mb-5 flex flex-row min-w-[500px] justify-between">
            <PrimaryButton onClicking={() => togggleAll()}>
              <div className="px-5 py-2">
                <BiExpand size={18} />
              </div>
            </PrimaryButton>
            <div className="flex space-x-3">
              {!isDifferentSerial && (
                <PrimaryButton onClicking={() => saveSortedeOrder()}>
                  <div className="px-3 py-2">
                    <GiSave size={18} />
                  </div>
                </PrimaryButton>
              )}
              {!isDifferentSerial && (
                <PrimaryButton onClicking={resetDealers}>
                  <div className="px-3 py-2">
                    <BiReset size={18} />
                  </div>
                </PrimaryButton>
              )}
              <PrimaryButton onClicking={() => setIsShowCreate(true)}>
                <div className="px-3 py-2">
                  <BsPlusCircle size={18} />
                </div>
              </PrimaryButton>
            </div>
          </div>

          <div className="flex flex-row max-w-[1280px]">
            <Droppable droppableId="cardDroppable">
              {(provider) => (
                <div
                  className="space-y-5"
                  ref={provider.innerRef}
                  {...provider.droppableProps}
                >
                  {data &&
                    data.map((banner, index) => (
                      <div
                        key={banner.order}
                        className="w-full rounded-l-[20px] text-sm shadow-md desktop:w-full"
                      >
                        <Draggable
                          key={banner.order}
                          draggableId={`DraggableCard${banner.order}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="flex flex-col desktop:flex-row"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {banner?.imagefile && (
                                <div className="flex overflow-x-auto max-w-[700px] h-[300px]">
                                  {banner?.imagefile?.map((img, i) => (
                                    // <div key={i} className="">
                                    <img
                                      key={i}
                                      className="h-full w-full"
                                      loader={() => `${img}`}
                                      src={`/bucket/motor${img}`}
                                      // width={1440}
                                      // height={573}
                                      alt="agb Cover"
                                    />
                                    // </div>
                                  ))}
                                </div>
                              )}

                              <div
                                className="w-full"
                                style={{ display: "contents" }}
                                id={`CoverDetail${index}`}
                              >
                                <div className="w-full bg-white px-5 py-2 pb-5">
                                  <div className="flex w-full items-center justify-between space-x-3 border-b border-[#D8D8D8] py-2">
                                    <div className="flex flex-col space-x-2 desktop:flex-row">
                                      <div className={`${styles.labelStyle}`}>
                                        Order No :
                                      </div>
                                      <div>{banner.order}</div>
                                    </div>
                                    <div className="flex flex-col items-center space-x-2 desktop:flex-row">
                                      <div className={`${styles.labelStyle}`}>
                                        Publish :
                                      </div>
                                      <div
                                        className={`${
                                          banner.publish
                                            ? "bg-[#2962ff] text-white"
                                            : "bg-[#c3c3c3] text-[#000]"
                                        } rounded-md px-2 py-1  shadow-md`}
                                      >
                                        {banner.publish
                                          ? "Published"
                                          : "Unpublished"}
                                      </div>
                                    </div>

                                    <div className="flex space-x-2">
                                      <PrimaryButton
                                        onClicking={() => {
                                          editDealer(banner);
                                        }}
                                      >
                                        <div className="px-2 py-1">
                                          <FiEdit size={18} />
                                        </div>
                                      </PrimaryButton>
                                      <PrimaryButton
                                        onClicking={() =>
                                          deleteDealer(banner._id)
                                        }
                                        bgColor="red"
                                      >
                                        <div className="px-2 py-1">
                                          <MdDeleteOutline size={18} />
                                        </div>
                                      </PrimaryButton>
                                    </div>
                                  </div>

                                  {/* <div className="flex w-full items-center justify-between border-b border-[#D8D8D8] py-2">
                                    <div className={styles.labelStyle}>
                                      File :
                                    </div>
                                    <div>{banner.imagefile}</div>
                                  </div> */}
                                </div>
                              </div>
                              <button
                                onClick={() => ToggleDetail(index)}
                                type="button"
                                className="rounded-b-[20px] bg-[#2962ff] text-xl text-white desktop:rounded-r-[20px] desktop:rounded-bl-[0px]"
                              >
                                <div
                                  className="rotate-90 desktop:rotate-180"
                                  id={`CoverShowBtn${index}`}
                                >
                                  &#10147;
                                </div>
                              </button>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    </>
  );
}

DealerComponent.propTypes = {
  dealerData: PropsTypes.arrayOf(
    PropsTypes.shape({
      _id: PropsTypes.string,
      buttonurl: PropsTypes.string,
      content: PropsTypes.string,
      createdAt: PropsTypes.string,
      order: PropsTypes.number,
      title: PropsTypes.string,
      overlayer: PropsTypes.bool,
      publish: PropsTypes.bool,
      imagefile: PropsTypes.string,
    })
  ).isRequired,
  refresh: PropsTypes.func.isRequired,
};

export default DealerComponent;
