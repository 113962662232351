import React from "react";
import PropTypes from "prop-types";
import { FaFileDownload } from "react-icons/fa";

function CustomizedPDFCell({ cell }) {
  return (
    <div className="flex justify-center items-center h-full">
      <a
        href={`/bucket/motor${cell.value}`}
        download
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 cursor-pointer"
      >
        <FaFileDownload size={30} />
      </a>
    </div>
  );
}

CustomizedPDFCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default CustomizedPDFCell;
