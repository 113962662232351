import React, { useRef } from "react";
import { XIcon } from "@heroicons/react/outline";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import styles from "./style.module.css";

function DialogBox({ title, children, onCloseModal, isOpen, titleColor }) {
  const nodeRef = useRef();
  return (
    <CSSTransition
      classNames={{
        enterActive: styles.MyClassEnterActive,
        enterDone: styles.MyClassEnterDone,
        exitActive: styles.MyClassExit,
        exitDone: styles.MyClassExitActive,
      }}
      nodeRef={nodeRef}
      in={isOpen}
      mountOnEnter
      unmountOnExit
      timeout={300}
    >
      <div
        ref={nodeRef}
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm"
      >
        <div className="relative w-[70%] h-screen tablet:w-4/12 my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-color-dark_secondary outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h5
                style={{ color: titleColor }}
                className="text-3xl text-color-secondary font-semibold"
              >
                {title}
              </h5>
              <button
                type="button"
                className="w-6 h-auto cursor-pointer t-color-secondary hover:primary-text-theme-color"
                onClick={onCloseModal}
              >
                <XIcon className="w-full h-full" />
              </button>
            </div>
            <div className="relative p-6 flex-auto">{children}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default DialogBox;
