import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import propTypes from "prop-types";
import axiosClient from "../../src/axios-client";
import PrimaryButton from "../../src/components/Buttons/PrimaryButton";
import errImg from "../../src/assets/images/11104.jpg";
import DialogBox from "../../src/components/ModalBox/DialogBox";

export const ErrContext = createContext();

function ERRBtn({ code, backToLogin, backToHistory, reloadWeb }) {
  const unAuthorized = 401;
  const notFound = 404;

  if (code === unAuthorized) {
    return (
      <PrimaryButton onClicking={backToLogin}>
        <div className="px-3 py-3">Login</div>
      </PrimaryButton>
    );
  }
  if (code === notFound) {
    return (
      <PrimaryButton onClicking={backToHistory}>
        <div className="px-3 py-3">Back</div>
      </PrimaryButton>
    );
  }

  return (
    <PrimaryButton onClicking={reloadWeb}>
      <div className="px-3 py-3">Retry</div>
    </PrimaryButton>
  );
}

ERRBtn.propTypes = {
  code: propTypes.number.isRequired,
  backToLogin: propTypes.func.isRequired,
  backToHistory: propTypes.func.isRequired,
  reloadWeb: propTypes.func.isRequired,
};

function ErrorProvider({ children }) {
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState({ code: 0, msg: "" });

  const errCloseHandler = () => {
    setIsError(false);
  };

  const backToLogin = async () => {
    window.location.replace("login");
  };

  const backToHistory = () => {
    window.location.history();
  };

  const reloadWeb = () => {
    window.location.reload();
  };

  return (
    <ErrContext.Provider value={[setIsError, setErrMsg]}>
      <DialogBox
        isOpen={isError}
        onCloseModal={() => errCloseHandler()}
        titleColor="#FF0000"
        title="Something went wrong!"
      >
        <div className="space-y-5">
          <div className="flex flex-col items-center">
            <img src={errImg} alt="err image" width={250} height="auto" />
            <div className="text-2xl font-semibold">{errMsg?.msg}</div>
          </div>
          <div className="flex w-full justify-end">
            <ERRBtn
              code={errMsg?.code}
              backToLogin={backToLogin}
              backToHistory={backToHistory}
              reloadWeb={reloadWeb}
            />
          </div>
        </div>
      </DialogBox>
      {children}
    </ErrContext.Provider>
  );
}

ErrorProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default ErrorProvider;
