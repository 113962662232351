import { BiExpand, BiReset } from "react-icons/bi";
import { GiSave } from "react-icons/gi";
import { BsPlusCircle } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import React, { useEffect, useMemo, useState } from "react";
import PropsTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import ModelUpdateForm from "../Forms/ModelUpdateForm";
import ModelCreateForm from "../Forms/ModelCreateForm";
import FormModal from "../FormModal";
import styles from "../BannerComponent/style.module.css";
import PrimaryButton from "../Buttons/PrimaryButton";
import CustomAlert from "../Notification/CustomAlert";
import axiosClient from "../../axios-client";

function modelComponent({ modelData, refresh, mutate }) {
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [updateDetail, setUpdateDetail] = useState({
    show: false,
    detail: null,
  });
  const [showDelAlert, setShowDelAlert] = useState({ id: "", show: false });
  const [data, setData] = useState(modelData);

  useEffect(() => {
    setData(modelData);
  }, [modelData]);

  const createModel = async (body) => {
    const resp = await axiosClient
      .post(`/backpanel/model`, body)
      .then(async (response) => {
        toast(response.data.message, { type: "success" });
        await refresh();
        return response;
      })
      .catch((err) => toast(err.message, { type: "error" }));

    return resp;
  };

  const updateModel = async (body) => {
    try {
      const response = await axiosClient.put(
        `/backpanel/model/${updateDetail.detail._id}`,
        body
      );
      toast(response.data.message, { type: "success" });
      await refresh();
      return response;
    } catch (err) {
      toast(err?.response?.data?.message || "Something went wrong", {
        type: "error",
      });
      return undefined;
    }
  };

  const removeModel = async (id) => {
    const resp = await axiosClient
      .delete(`/backpanel/model/${id}`)
      .then((response) => {
        toast(response.data.message, { type: "success" });
        refresh();

        return response;
      })
      .catch((err) =>
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        })
      );
    return resp;
  };

  const submitModel = async (newData, type) => {
    if (type === "create") {
      const resp = await createModel(newData);
      if (resp) {
        setIsShowCreate(false);
      }
    } else if (type === "edit") {
      const resp = await updateModel(newData);

      if (resp) {
        setUpdateDetail({
          ...updateDetail,
          show: false,
        });
      }
    }
  };

  const ToggleDetail = (index) => {
    const detailList = document.getElementById(`CoverDetail${index}`);
    const detailBtn = document.getElementById(`CoverShowBtn${index}`);
    if (detailList.style.display === "none") {
      detailList.style.display = "contents";
      detailBtn.style.transform = "rotate(180deg)";
      detailBtn.style.transition = "transform 300ms ease-in-out";
    } else {
      detailList.style.display = "none";
      detailBtn.style.transform = "rotate(0deg)";
    }
  };

  const togggleAll = () => {
    data.map((value, index) => ToggleDetail(index));
  };

  const deleteModel = (bannerId) => {
    setShowDelAlert({
      id: bannerId,
      show: true,
    });
  };

  const conFirmDelete = async () => {
    const response = await removeModel(showDelAlert.id);
    if (response) {
      await refresh();
      setShowDelAlert({
        id: "",
        show: false,
      });

      await refresh();
    }
  };

  const editModel = (bData) => {
    setUpdateDetail({
      show: true,
      detail: bData,
    });
  };

  return (
    <>
      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>
      <CustomAlert
        btnColor="red"
        message="Are you sure want to delete!!!"
        btnLabel="Delete"
        open={showDelAlert.show}
        onClose={() => setShowDelAlert({ ...showDelAlert, show: false })}
        onBtnClick={() => conFirmDelete()}
        type="warn"
      />

      <FormModal
        header="Update Banner"
        isOpen={updateDetail.show}
        title="Update Banner"
      >
        <ModelUpdateForm
          type="editForm"
          modelData={updateDetail.detail}
          previewId="previewDialog"
          onCancel={() => setUpdateDetail({ ...updateDetail, show: false })}
          onConfirm={(e) => submitModel(e, "edit")}
        />
      </FormModal>

      <FormModal
        header="Create Model"
        isOpen={isShowCreate}
        title="Create Model"
      >
        <ModelCreateForm
          type="createForm"
          previewId="previewDialog"
          onCancel={() => setIsShowCreate(false)}
          onConfirm={(e) => submitModel(e, "create")}
        />
      </FormModal>

      <div className="flex flex-col">
        <div className="mb-5 flex flex-row min-w-[500px] justify-between">
          <PrimaryButton onClicking={() => togggleAll()}>
            <div className="px-5 py-2">
              <BiExpand size={18} />
            </div>
          </PrimaryButton>
          <div className="flex space-x-3">
            <PrimaryButton onClicking={() => setIsShowCreate(true)}>
              <div className="px-3 py-2">
                <BsPlusCircle size={18} />
              </div>
            </PrimaryButton>
          </div>
        </div>

        <div className="flex flex-row max-w-[1280px]">
          <div className="space-y-5">
            {data &&
              data.map((model, index) => (
                <div
                  key={model.order}
                  className="w-full rounded-l-[20px] text-sm shadow-md desktop:w-full"
                >
                  <div className="flex flex-col desktop:flex-row">
                    <img
                      className="h-auto w-full rounded-t-[20px] desktop:w-[500px] desktop:rounded-l-[20px] desktop:rounded-tr-[0px]"
                      // loader={() => `${banner.imagefile}`}
                      src={`/bucket/motor${model?.brand_id?.brand_logo}`}
                      width={1440}
                      height={573}
                      alt="agb Cover"
                    />
                    <div
                      className="w-full"
                      style={{ display: "contents" }}
                      id={`CoverDetail${index}`}
                    >
                      <div className="w-full bg-white px-5 py-2 pb-5">
                        <div className="flex w-full items-center justify-between space-x-3 border-b border-[#D8D8D8] py-2">
                          <div className="flex flex-col items-center space-x-2 desktop:flex-row">
                            <div className={`${styles.labelStyle}`}>
                              Model Name :
                            </div>
                            <div>{model.model_name}</div>
                          </div>

                          <div className="flex space-x-2">
                            <PrimaryButton
                              onClicking={() => {
                                editModel(model);
                              }}
                            >
                              <div className="px-2 py-1">
                                <FiEdit size={18} />
                              </div>
                            </PrimaryButton>
                            <PrimaryButton
                              onClicking={() => deleteModel(model._id)}
                              bgColor="red"
                            >
                              <div className="px-2 py-1">
                                <MdDeleteOutline size={18} />
                              </div>
                            </PrimaryButton>
                          </div>
                        </div>

                        <div className="flex w-full items-center justify-between border-b border-[#D8D8D8] py-2">
                          <div className={styles.labelStyle}>File :</div>
                          <div>{model?.brand_id?.brand_logo}</div>
                        </div>
                        <div className="flex w-full items-center justify-between border-b border-[#D8D8D8] py-2">
                          <div className={styles.labelStyle}>Type :</div>
                          {model?.type?.map((type) => (
                            <div>{type}</div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => ToggleDetail(index)}
                      type="button"
                      className="rounded-b-[20px] bg-[#2962ff] text-xl text-white desktop:rounded-r-[20px] desktop:rounded-bl-[0px]"
                    >
                      <div
                        className="rotate-90 desktop:rotate-180"
                        id={`CoverShowBtn${index}`}
                      >
                        &#10147;
                      </div>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

modelComponent.propTypes = {
  modelData: PropsTypes.arrayOf(
    PropsTypes.shape({
      _id: PropsTypes.string,
      buttonurl: PropsTypes.string,
      content: PropsTypes.string,
      createdAt: PropsTypes.string,
      order: PropsTypes.number,
      title: PropsTypes.string,
      overlayer: PropsTypes.bool,
      publish: PropsTypes.bool,
      imagefile: PropsTypes.string,
    })
  ).isRequired,
  refresh: PropsTypes.func.isRequired,
};

export default modelComponent;
