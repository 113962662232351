import React from "react";
import { MdOutlineDriveEta } from "react-icons/md";
import ImageIcon from "./icons/medias";
import { FiMapPin } from "react-icons/fi";
import EntertainmentIcon from "./icons/entertainment";
import OrganizationIcon from "./icons/organization";
import NewsIcon from "./icons/news";
import JobIcon from "./icons/job";
import { FaCarSide } from "react-icons/fa";

const data = [
  {
    title: "Banner",
    icon: <ImageIcon />,
    link: "/banner",
    permission: ["banner"],
  },

  {
    title: "Career",
    icon: <JobIcon size={23} />,
    link: "/career",
    permission: ["career"],
  },

  {
    title: "News",
    icon: <NewsIcon size={23} />,
    link: "/news",
    permission: ["news"],
  },

  {
    title: "Register",
    icon: <OrganizationIcon size={23} />,
    link: "/register",
    permission: ["register"],
  },
  {
    title: "Dealer",
    icon: <FiMapPin size={23} />,
    link: "/dealer",
    permission: ["dealer"],
  },
  {
    title: "Station",
    icon: <EntertainmentIcon size={23} />,
    link: "/station",
    permission: ["station"],
  },

  {
    title: "Vehicle",
    permission: ["vehicle"],
    icon: <MdOutlineDriveEta size={23} />,
    // link: "/brand",

    options: [
      {
        title: "Brand",

        link: "/brand",
      },
      {
        title: "Model",
        link: "/model",
      },
      {
        title: "File",
        link: "/file",
      },
      {
        title: "Add Car Info",
        link: "/addCar",
      },
      {
        title: "Build Vehicle",
        link: "/vehicle",
      },
    ],
  },

  {
    title: "Test Drive",
    icon: <FaCarSide size={23} />,
    link: "/test-drive",
  },
];

export default data;
