import React from "react";
import axiosClient from "../../axios-client";
import useSWR from "swr";
import BrandComponent from "../../components/BrandComponent";
import MainLoading from "../../components/MainLoading";
import useSWRMutation from "swr/mutation";
import Header from "../../components/PageHeader";
const Brand = () => {
  const fetcher = async (url) => {
    try {
      const response = await axiosClient.get(url);
      return response?.data?.data;
    } catch (err) {
      console.error("Error fetching brands:", err);
      return undefined;
    }
  };

  const {
    data: brands,
    mutate,
    isLoading,
  } = useSWR(`/backpanel/brand`, fetcher);

  const { trigger, isMutating } = useSWRMutation(`/backpanel/brand`, fetcher);

  if (isLoading) return <MainLoading enter={isLoading} />;

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isLoading} />
      <Header pageName="Brand" />
      <div className="flex justify-center">
        {brands ? (
          <BrandComponent
            brandData={brands}
            refresh={() => trigger(`/backpanel/brand`)}
          />
        ) : (
          <div>No brands found.</div>
        )}
      </div>
    </>
  );
};

export default Brand;
