import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axiosClient from "../../../axios-client";
import { ToastContainer, toast } from "react-toastify";
import { mutate } from "swr";

function CustomizedDisableCell({ cell }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = (id) => {
    if (cell.value === "true") {
      fetchEnable(id);
    } else {
      setShowConfirmation(true);
    }
  };

  const fetchDisable = async (id) => {
    try {
      const response = await axiosClient?.put(`/backpanel/disable/${id}`);

      if (response?.status === 200) {
        toast(response?.data?.message, { type: "success" });
        setShowConfirmation(false);
        mutate();
        return response;
      }
    } catch (error) {
      toast(error?.response?.data?.message || "Something went wrong", {
        type: "error",
      });
      return undefined;
    }
  };

  const fetchEnable = async (id) => {
    try {
      const response = await axiosClient?.put(`/backpanel/enable/${id}`);

      if (response?.status === 200) {
        toast(response?.data?.message, { type: "success" });
        mutate();
        return response;
      }
    } catch (error) {
      toast(error?.response?.data?.message || "Something went wrong", {
        type: "error",
      });
      return undefined;
    }
  };
  const handleConfirm = (id) => {
    fetchDisable(id);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <div>
      <div
        style={{ backgroundColor: cell.value === "true" ? "red" : "green" }}
        className="text-white font-semibold rounded-md py-2 cursor-pointer"
        onClick={() => handleButtonClick(cell?.row?.original?._id)}
      >
        {cell.value === "true" ? "Disabled" : "Enabled"}
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-5 rounded-lg shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to{" "}
              {cell.value === "true" ? "enable" : "disable"} this user?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-red-600 text-white px-4 py-2 rounded-md"
                onClick={() => handleConfirm(cell?.row?.original?._id)}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

CustomizedDisableCell.propTypes = {
  cell: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.bool])
  ).isRequired,
};

export default CustomizedDisableCell;
