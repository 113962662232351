import React from "react";
import PropTypes from "prop-types";

function CustomizePositionCell({ cell }) {
  return <div>{cell.value}</div>;
}
CustomizePositionCell.propTypes = {
  cell: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.bool])
  ).isRequired,
};

export default CustomizePositionCell;
