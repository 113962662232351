import React, { useContext, useEffect, useMemo, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";

import ActionComponent from "../../components/Table/Components/ActionComponent";

import CustomAlert from "../../components/Notification/CustomAlert";

import LoadingComponent from "../../components/Loading";

import CustomizedTable from "../../components/Table/CustomizedTable";

import FormModal from "../../components/FormModal";
import Header from "../../components/PageHeader";
import MainLoading from "../../components/MainLoading";
import "react-toastify/dist/ReactToastify.css";
import axiosClient from "../../axios-client";
import { ErrContext } from "../../providers/ErrorProvider";
import CustomizedPDFCell from "../../components/Table/Components/CustomizedPDFCell";
import SpecificationFileForm from "../../components/Forms/SpecificationFileForm";

export default function File() {
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [isShowCreate, setIsShowCreate] = useState(false);

  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,

    message: "",

    type: "",

    btnLabel: "",

    btnColor: "",

    onBtnClick: null,
  });

  const defaultData = {
    _id: "",
    name: "",
    pdffile: "",
  };

  const [formData, setFormData] = useState(defaultData);
  const [setIsError, setErrMsg] = useContext(ErrContext);

  // get all file
  const fetchData = async ([pageParams, limitParams]) => {
    let resp;
    resp = await axiosClient
      .get(`/backpanel/file?page=${pageParams}&limit=${limitParams}`)
      .then((response) => response?.data)
      .catch((err) => {
        setIsError(true);
        setErrMsg({ code: err.response, msg: err.message });
      });

    return resp;
  };

  const { data: file, mutate, isLoading } = useSWR([page, limit], fetchData);

  const { trigger, isMutating } = useSWRMutation([page, limit], fetchData);

  useEffect(() => {
    trigger({ ...file, page, limit });

    mutate();
  }, []);

  //add file
  const createFile = async (body) => {
    const resp = await axiosClient
      .post(`/backpanel/file`, body)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });
        setIsShowCreate(false);
        mutate();
        return response;
      })
      .catch((err) => {
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });

    return resp;
  };

  const submitFile = async (data, type, removePdf, pdfFile) => {
    if (type === "create") {
      const formData = new FormData();

      formData.append("pdffile", pdfFile);

      formData.append("name", data.name);

      const resp = await createFile(formData);
    } else if (type === "edit") {
      const fData = new FormData();
      fData.append("pdffile", pdfFile);

      fData.append("name", data.name);
      fData.append(
        "removePdf",
        removePdf ? JSON.stringify(removePdf) : JSON.stringify([])
      );

      updateFile(fData);
    }
  };

  const editData = async (row) => {
    const tableData = { ...row };

    setFormData(tableData);
    setIsOpenEdit(true);
  };

  const deleteData = async (row) => {
    setAlertState({
      ...alertState,
      open: false,
    });

    await axiosClient
      .delete(`/backpanel/file/${row?._id}`)
      .then((response) => {
        toast(response?.data?.message || "File deleted successfully", {
          type: "success",
        });
      })
      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    mutate();
    // await trigger({ ...file, page, limit });
  };

  const updateFile = async (body) => {
    const resp = await axiosClient
      .put(`/backpanel/file/${formData?._id}`, body)

      .then(async (response) => {
        await mutate();

        setIsOpenEdit(false);

        toast(response?.data?.message, { type: "success" });

        return response;
      })

      .catch((err) => {
        toast(err?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });

    setFormData({
      ...formData,
    });

    return resp;
  };

  const columns = useMemo(() => {
    if (!file) return [];

    const columnData = [
      {
        Header: "Action",

        accessor: "action",

        filter: false,

        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),

        width: 100,
      },

      ...file?.columns,
    ];

    const changeCol = columnData.map((col) => {
      const column = col;

      if (column.accessor === "pdffile") {
        column.Cell = ({ cell }) => CustomizedPDFCell({ cell });

        column.width = 50;
      }

      return column;
    });

    return changeCol;
  }, [file]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;

      tempColumn.show = true;

      return tempColumn;
    });

    return global;
  }, [columns]);

  if (isLoading || !file || isMutating) {
    return <MainLoading enter={isLoading || isMutating} bg="#000F3C4D" />;
  }

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isMutating} />

      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>

      <Header pageName="File" />

      <CustomizedTable
        dataRows={file?.data}
        dataColumns={columns}
        totalItems={file?.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        loading={isLoading}
        onCreate={() => setIsShowCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...file, page, limit })}
        colWithShow={globalColumn}
      />
      <FormModal header="Create File" isOpen={isShowCreate} title="Create File">
        <SpecificationFileForm
          type="createForm"
          formData={formData}
          onClose={() => setIsShowCreate(false)}
          onConfirm={(data, pdfFile) =>
            submitFile(data, "create", null, pdfFile)
          }
        />
      </FormModal>

      <FormModal isOpen={isOpenEdit} header="Edit file">
        <SpecificationFileForm
          type="editForm"
          formData={formData}
          onClose={() => {
            setFormData({
              ...formData,
            });

            setIsOpenEdit(false);
          }}
          onConfirm={(data, pdfFile, removePdf) =>
            submitFile(data, "edit", removePdf, pdfFile)
          }
        />
      </FormModal>
      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      />
    </>
  );
}
