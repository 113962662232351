import { createBrowserRouter, Navigate } from "react-router-dom";
import DashboardLayout from "./dashboard/layout";
import BannerPage from "./pages/banner/BannerPage";
import Login from "./pages/auth/Login";
import Brand from "./pages/brand/Brand";
import Register from "./pages/register/Register";
import NewsAndEvents from "./pages/news/News";
import CarrerPage from "./pages/career/Career";
import Dealer from "./pages/dealer/Dealer";
import StationPage from "./pages/station/Station";
import BrandComponent from "./components/ModelComponent";
import Model from "./pages/model/Model";
import AddCar from "./pages/addCar/AddCar";
import Vehicle from "./pages/vehicle/Vehicle";
import File from "./pages/file/File";
import TestDrivePage from "./pages/test-drive/TestDrive";
import ContactusPage from "./pages/contactus/ContactusPage";
export const routes = createBrowserRouter([
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/banner",
        element: <BannerPage />,
      },
      {
        path: "/brand",
        element: <Brand />,
      },

      {
        path: "/contactus",
        element: <ContactusPage />,
      },
      {
        path: "/news",
        element: <NewsAndEvents />,
      },
      {
        path: "/career",
        element: <CarrerPage />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/dealer",
        element: <Dealer />,
      },
      {
        path: "/station",
        element: <StationPage />,
      },
      {
        path: "/brand",
        element: <BrandComponent />,
      },
      {
        path: "/model",
        element: <Model />,
      },
      {
        path: "/addCar",
        element: <AddCar />,
      },
      {
        path: "/vehicle",
        element: <Vehicle />,
      },
      {
        path: "/file",
        element: <File />,
      },
      {
        path: "/test-drive",
        element: <TestDrivePage />,
      },

      {
        path: "/",
        element: <Navigate to="/banner" />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
