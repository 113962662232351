import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../Buttons/PrimaryButton";
import axiosClient from "../../axios-client";
import HookFormInput from "../Inputs/HookFormInput";
import HookFormSelect from "../Inputs/BrandHookFormSelect";

function ModelCreateForm({ type, modelData, onCancel, previewId, onConfirm }) {
  const [brands, setBrands] = useState([]);
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      model_name: type === "editForm" ? modelData.model_name : "",
      brand_id: type === "editForm" ? modelData.brand_id : "",
      type: type === "editForm" ? modelData.type : [],
    },
  });

  useEffect(() => {
    const fetcher = async () => {
      try {
        const response = await axiosClient.get("/backpanel/brand");
        const data = response?.data?.data;
        setBrands(data);
      } catch (err) {
        console.error("Error fetching Models:", err);
        return undefined;
      }
    };

    fetcher();
  }, []);
  const style = {
    label: "text-colors-primary text-base font-semibold",
    inputWrapper: "tablet:w-[45%]",
  };

  let selectedItems;
  selectedItems = watch("type", []);
  return (
    <div className="sticky top-0 w-full px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
        {/* brand lists */}
        <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
          <div className={`${style.inputWrapper}`}>
            <label
              htmlFor="brand_id"
              className="block text-sm font-medium text-gray-700"
            >
              Select Brand *
            </label>
            <select
              id="brand_id"
              {...register("brand_id", { required: "Brand is required" })}
              className="w-full  rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme"
            >
              <option value="">Select a Brand</option>
              {brands.length !== 0 &&
                brands.map((brand) => (
                  <option key={brand?._id} value={brand?._id}>
                    {brand.brand_name}
                  </option>
                ))}
            </select>
          </div>
          <div className={`${style.inputWrapper}`}>
            <div className={style.labelStyle} htmlFor="type">
              Model Type *
            </div>
            <HookFormSelect
              setValue={setValue}
              option={["ev", "ice", "hybrid"]}
              label="type"
              register={register}
              errors={errors}
              selectedItems={selectedItems}
            />
          </div>{" "}
        </div>

        {/* model name */}
        <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
          <div className={`${style.inputWrapper}`}>
            <div className=""> Model Name * </div>
            <HookFormInput
              label="model_name"
              placeholder="Model Name"
              validate={{ required: true }}
              register={register}
              errors={errors}
            />
          </div>
          <div className={`${style.inputWrapper}`}></div>
        </div>

        <div className="flex items-center justify-end space-x-5">
          <PrimaryButton onClicking={onCancel}>
            <div className="px-4 py-3">Cancel</div>
          </PrimaryButton>
          <PrimaryButton onClicking={handleSubmit((data) => onConfirm(data))}>
            <div className="px-4 py-3">
              {type === "createForm" ? "Create" : "Update"}
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default ModelCreateForm;
