import React from "react";
import PropTypes from "prop-types";

function CustomizeIsremoteCell({ cell }) {
  return (
    <div
      style={{ backgroundColor: cell.value === true ? "orange" : "blue" }}
      className="text-white font-semibold rounded-md py-2"
    >
      {" "}
      {cell.value === true ? "Remote" : "On-Site"}{" "}
    </div>
  );
}
CustomizeIsremoteCell.propTypes = {
  cell: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.bool])
  ).isRequired,
};

export default CustomizeIsremoteCell;
