/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import propTypes from "prop-types";

function HookFormSelect({
  name,
  label,
  validate,
  register,
  option,
  disabled = false,
  selected = "",
  errors,
}) {
  return (
    <div className="w-full">
      <label htmlFor={label}>
        <select
          disabled={disabled}
          className="w-full rounded-md border border-theme px-3 py-2 text-theme"
          {...register(label, validate)}
        >
          <option value="" disabled>
            {" "}
            Select
          </option>
          {option &&
            option?.map((value) => (
              <option
                defaultValue={selected}
                className="px-3 py-2"
                key={value}
                value={value._id}
              >
                {name === "edition"
                  ? value?.edition_name
                  : name === "model"
                  ? value?.brand_name
                  : value?.name}
              </option>
            ))}
        </select>
      </label>
      {errors && errors[label]?.type === "required" && (
        <p className="mt-2 text-red-500">This field is required</p>
      )}
    </div>
  );
}

HookFormSelect.propTypes = {
  label: propTypes.string.isRequired,
  validate: propTypes.objectOf(
    propTypes.oneOfType([propTypes.bool, propTypes.number])
  ).isRequired,
  register: propTypes.func.isRequired,
  option: propTypes.arrayOf(propTypes.string).isRequired,
  disabled: propTypes.bool,
  selected: propTypes.string,
  errors: propTypes.objectOf(
    propTypes.oneOfType([propTypes.object, propTypes.string])
  ).isRequired,
};

HookFormSelect.defaultProps = {
  disabled: false,
  selected: "",
};

export default HookFormSelect;
