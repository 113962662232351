import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { useForm } from "react-hook-form";
import HookFormInput from "../Inputs/HookFormInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import styles from "./style.module.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Controller } from "react-hook-form";

import { GiCancel } from "react-icons/gi";

function StationForm({ type, onConfirm, onClose, formData }) {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [existingImages, setExistingImages] = useState(
    type === "editForm" ? formData?.imagefile : []
  );
  const [removeImages, setRemoveImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [images, setImages] = useState();
  const [imageUrls, setImageUrls] = useState();

  const nameRef = useRef(null);
  const stationTypeRef = useRef(null);
  const addressRef = useRef(null);
  const positionRef = useRef(null);
  const phoneRef = useRef(null);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: type === "editForm" ? formData?.name : "",
      address: type === "editForm" ? formData?.address : "",
      type: type === "editForm" ? formData?.type : "",
      phone: type === "editForm" ? formData?.phone : "",
      position: type === "editForm" ? formData?.position : [],
      publish: type === "editForm" ? formData?.publish : false,
      imagefile: type === "editForm" ? formData?.imagefile : [],
    },
    mode: "onChange",
  });

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    const latitude = formData?.position[0];
    setValue("lat", latitude);
    const langitude = formData?.position[1];
    setValue("lang", langitude);
  }, [formData?.position]);

  const onUpload = (e) => {
    const filesArray = Array.from(e.target.files);
    setNewImages((prevImages) => [...prevImages, ...filesArray]);
  };

  const handleRemoveNewImage = (index) => {
    setNewImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const handleRemoveExistingImage = (image, index) => {
    setExistingImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setRemoveImages((prevImages) => [...prevImages, image]);
  };

  return (
    <div className="px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
        {/* publish */}
        <div className="justify-around tablet:flex">
          <div className="mt-5 flex justify-around space-x-5 tablet:mt-0">
            <div className="flex items-center space-x-3">
              <div className={styles.labelStyle} htmlFor="publish">
                {" "}
                Publish{" "}
              </div>
              <HookFormInput
                type="checkbox"
                placeholder="publish"
                label="publish"
                validate={{ required: false }}
                register={register}
                errors={errors}
              />
            </div>
          </div>
        </div>

        {/* name */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="name">
            Name :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <Controller
                name="name"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <CKEditor
                    ref={nameRef}
                    editor={ClassicEditor}
                    id="rich-text-editor"
                    data={field.value || ""}
                    onReady={(editor) => {
                      editor?.editing?.view?.change((writer) => {
                        writer?.setStyle(
                          "height",
                          "200px",
                          editor?.editing?.view?.document?.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      const data = editor?.getData();
                      field.onChange(data);
                    }}
                  />
                )}
              />
            ) : (
              <div>Editor Loading</div>
            )}
            {errors?.name && (
              <p className="mt-2 text-red-500">{errors.name.message}</p>
            )}
          </div>
        </div>

        {/* address */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="address">
            Address :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <Controller
                name="address"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <CKEditor
                    ref={addressRef}
                    editor={ClassicEditor}
                    id="rich-text-editor"
                    data={field.value || ""}
                    onReady={(editor) => {
                      editor?.editing?.view?.change((writer) => {
                        writer?.setStyle(
                          "height",
                          "200px",
                          editor?.editing?.view?.document?.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      const data = editor?.getData();
                      field.onChange(data);
                    }}
                  />
                )}
              />
            ) : (
              <div>Editor Loading</div>
            )}
            {errors?.address && (
              <p className="mt-2 text-red-500">{errors.address.message}</p>
            )}
          </div>
        </div>

        {/* phone */}
        <div className="w-1/2 space-y-5 laptop:space-y-0">
          <div className={styles.inputWrapper}>
            <div className={styles.label}>Phone *</div>
            <HookFormInput
              placeholder="Phone"
              label="phone"
              validate={{ required: true }}
              register={register}
              errors={errors}
            />
          </div>
        </div>

        {/* type*/}
        <div className="w-1/2 space-y-5 laptop:space-y-0">
          <div className={styles.inputWrapper}>
            <div className={styles.label}>Type *</div>
            <HookFormInput
              placeholder="Type"
              label="type"
              validate={{ required: true }}
              register={register}
              errors={errors}
            />
          </div>
        </div>

        {/* lat  lng*/}
        <div className="w-full space-y-5 laptop:flex laptop:justify-between laptop:space-y-0 gap-x-4">
          <div className={`${styles.inputWrapper} w-1/2`}>
            <div className={styles.label}>Lat *</div>
            <HookFormInput
              placeholder="Latitude"
              label="lat"
              validate={{ required: true }}
              register={register}
              errors={errors}
            />
          </div>
          <div className={`${styles.inputWrapper} w-1/2`}>
            <div className={styles.label}>Lang *</div>
            <HookFormInput
              placeholder="Langitude"
              label="lang"
              validate={{ required: true }}
              register={register}
              errors={errors}
            />
          </div>
        </div>

        {/* upload img */}
        <div className="w-full flex flex-col items-center gap-4">
          <label htmlFor="img_input" className={styles.img_label}>
            Select Image
            <input
              id="img_input"
              className={styles.img_input}
              multiple
              type="file"
              accept="image/*"
              onChange={onUpload}
            />
          </label>

          <div
            className={`flex flex-row h-[200px] w-full overflow-hidden overflow-x-auto  border border-dashed border-[#000f3c]`}
          >
            {existingImages?.map((image, index) => (
              <div
                key={index}
                className="flex flex-col relative items-center mr-2"
              >
                <img
                  src={`/bucket/motor${image}`}
                  alt={`Preview ${index + 1}`}
                  style={{
                    minWidth: "200px",
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />

                <button
                  className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                  onClick={() => handleRemoveExistingImage(image, index)}
                >
                  <GiCancel size={12} />
                </button>
              </div>
            ))}
            {newImages?.map((image, index) => (
              <div
                key={index}
                className="flex flex-col relative items-center mr-2"
              >
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Preview ${index + 1}`}
                  style={{
                    minWidth: "200px",
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />

                <button
                  className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                  onClick={() => handleRemoveNewImage(index)}
                >
                  <GiCancel size={12} />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-end space-x-5">
          <PrimaryButton onClicking={onClose}>
            <div className="px-4 py-3">Cancel</div>
          </PrimaryButton>
          <PrimaryButton
            onClicking={handleSubmit((data) => {
              onConfirm(data, newImages, removeImages);
            })}
            isDisabled={
              type === "createForm"
                ? newImages?.length !== 3
                : !(
                    (existingImages.length === 3 && newImages.length === 0) ||
                    (existingImages.length === 0 && newImages.length === 3) ||
                    existingImages.length + newImages.length === 3
                  )
            }
          >
            <div className="px-4 py-3">
              {type === "createForm" ? "Create" : "Update"}
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default StationForm;

StationForm.propTypes = {
  type: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  onConfirm: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  formData: propTypes.shape({
    name: propTypes.string,
    title: propTypes.string,
    tandc: propTypes.string,
    benefits: propTypes.string,
    publish: propTypes.bool,
    type: propTypes.string,
    order: propTypes.number,
  }).isRequired,
};
