import React, { useEffect, useContext } from "react";
import axiosClient from "../../axios-client";
import axios from "axios";
import DealerComponent from "../../components/DealerComponent";

import { Navigate } from "react-router-dom";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import ErrContext from "../../providers/ErrorProvider";
import { setupAxiosInterceptors } from "../../axios-client";
import MainLoading from "../../components/MainLoading";

const Dealer = () => {
  // const [setIsError, setErrMsg] = useContext(ErrContext);

  const fetcher = async (url) => {
    try {
      const response = await axiosClient.get(url);

      return response?.data?.dealers;
    } catch (err) {
      // setIsError(true);
      // setErrMsg({ code: err.response?.status, msg: err.message });
      return undefined;
    }
  };

  const {
    data: dealers,
    mutate,
    isLoading,
  } = useSWR(`/backpanel/dealer`, fetcher);
  const { trigger, isMutating } = useSWRMutation(`/backpanel/dealer`, fetcher);

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isMutating} />
      <div className="flex justify-center">
        {dealers && (
          <DealerComponent
            dealerData={dealers}
            refresh={() => trigger(`/backpanel/dealer`)}
          />
        )}
      </div>
    </>
  );
};

export default Dealer;
