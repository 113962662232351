import React, { useEffect, useState, useContext } from "react";
import { ErrContext } from "../../providers/ErrorProvider";
import { ToastContainer, toast } from "react-toastify";
import PrimaryInput from "../../components/Inputs/PrimaryInput";
import AuthLayout from "../../components/AuthLayout";
import MainLoading from "../../components/MainLoading";
import { Hook } from "../../customHook/_signinHook";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const [setIsError, setErrMsg] = useContext(ErrContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [
    input,
    isShownPassword,
    userNameInput,
    passwordInput,
    /**
     * action
     */
    clickOnEyeIcons,
    handleChangeOnInput,
  ] = Hook();

  const [permission, setPermission] = useState([]);
  useEffect(() => {
    fetch(`/backpanel/route-permission-table`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPermission(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  const SignInHandler = async () => {
    setIsLoading(true);
    const payload = {
      username: input.userName.valueInput,
      password: input.password.valueInput,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    try {
      const res = await axios.post(`/backpanel/login-admin`, payload, config);

      localStorage.setItem("access_token", res?.data?.accessToken);
      const access_token = localStorage?.getItem("access_token");
      const decodedToken = jwtDecode(access_token);
      const userpermission = decodedToken?.permissions || [];
      console.log(permission);
      if (
        userpermission &&
        userpermission.length !== 0 &&
        permission &&
        permission.length !== 0
      ) {
        navigate(permission[userpermission[0]] || "/");
      }

      setIsLoading(false);
    } catch (err) {
      toast(err?.response?.data?.message || "Something went wrong", {
        type: "error",
      });

      setIsLoading(false);
    }
  };
  return (
    <AuthLayout>
      <MainLoading enter={isLoading} />
      <div className="flex h-full w-full items-center justify-center">
        <div className="space-y-8">
          <div className="mb-16 flex justify-center">
            <img
              src="/images/Motor_Logo.svg"
              height={150}
              width={150}
              alt="Free Admin logo"
            />
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <label className="body-font font-normal text-white">
                Username
              </label>
              <PrimaryInput
                type="text"
                id="userInput"
                name="userInput"
                inputRef={userNameInput}
                value={input.userName.valueInput}
                placeHolderText="Enter user name"
                focus={input.userName.focus}
                errorMessage={input.userName.errorText}
                handleChangeOnInput={handleChangeOnInput}
                keyDownHandler={() => null}
              />
            </div>
            <div className="space-y-2">
              <label className="body-font font-normal text-white">
                Password
              </label>
              <PrimaryInput
                type={isShownPassword ? "text" : "password"}
                id="passwordInput"
                name="passwordInput"
                inputRef={passwordInput}
                value={input.password.valueInput}
                placeHolderText="Enter password"
                focus={input.password.focus}
                errorMessage={input.password.errorText}
                icon={
                  isShownPassword ? (
                    <FaEye className="text-gray-400" />
                  ) : (
                    <FaEyeSlash className="text-white" />
                  )
                }
                keyDownHandler={(e) => {
                  if (e.key === "Enter") {
                    SignInHandler();
                  }
                }}
                handleClickOnIcon={clickOnEyeIcons}
                handleChangeOnInput={handleChangeOnInput}
              />
            </div>
          </div>
          <div className="">
            <PrimaryButton onClicking={() => SignInHandler()}>
              <div className="py-5">Sign In</div>
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer />
      </div>
    </AuthLayout>
  );
};

export default Login;
