import React from "react";
import axios from "axios";
import { BiLogOutCircle } from "react-icons/bi";
import { useToggle } from "../provider/context";
import { jwtDecode } from "jwt-decode";

import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";

export default function TopNavigation() {
  const { toggle, ref } = useToggle();
  const navigate = useNavigate();

  const access_token = localStorage?.getItem("access_token");
  const decodedToken = jwtDecode(access_token);

  return (
    <header
      className={`relative z-10 h-20 w-full items-center ${"bg-[#f5f5f5]"} `}
    >
      <div className="relative mx-auto flex h-full flex-col justify-center px-3">
        <div className="laptop:max-w-68 relative ml-0 flex w-full items-center pl-1 pr-2">
          <div className="relative left-0 flex w-3/4">
            <div className="group relative flex h-full w-12 items-center">
              <button
                type="button"
                aria-expanded="false"
                aria-label="Toggle sidenav"
                onClick={toggle}
                ref={ref}
                className="text-4xl text-[#2962ff] focus:outline-none laptop:hidden"
              >
                &#8801;
              </button>
            </div>
          </div>
          <div className="relative right-auto ml-5 mr-0 flex w-full items-center justify-end p-1">
            <div className="w-[200px] flex items-center justify-start gap-4 font-medium text-[#2962ff]  lg:mr-12   p-3">
              <FaRegUserCircle className="text-[20px] lg:text-[25px]" />
              <span className="text-sm lg:text-md">
                {decodedToken?.fullname}
              </span>
            </div>
            <button
              type="button"
              onClick={() => {
                axiosClient
                  .post(`/backpanel/logout`)
                  .then((res) => {
                    localStorage.removeItem("access_token");

                    navigate("/login");
                  })
                  .catch((e) => console.log(e));
              }}
              className="block pr-5"
            >
              <BiLogOutCircle
                className="text-[#2196f3] hover:text-[#2962ff]"
                size={25}
              />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
