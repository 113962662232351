import React, { useContext, useEffect, useMemo, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import useSWR from "swr";

import useSWRMutation from "swr/mutation";

import LoadingComponent from "../../../src/components/Loading";

import CustomizedTable from "../../components/Table/CustomizedTable";

import MainLoading from "../../../src/components/MainLoading";

import "react-toastify/dist/ReactToastify.css";
import { ErrContext } from "../../../src/providers/ErrorProvider";
import Header from "../../components/PageHeader";
import axiosClient from "../../axios-client";

export default function TestDrivePage() {
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [isShowCreate, setIsShowCreate] = useState(false);

  const [setIsError, setErrMsg] = useContext(ErrContext);

  const fetchData = async ([pageParams, limitParams]) => {
    let resp;
    resp = await axiosClient
      .get(`/backpanel/test-drive?page=${pageParams}&limit=${limitParams}`)
      .then((response) => response?.data)
      .catch((err) => {
        setIsError(true);
        setErrMsg({ code: err.response.status, msg: err.message });
      });

    return resp;
  };

  const {
    data: testDrive,
    mutate,
    isLoading,
  } = useSWR([page, limit], fetchData);

  const { trigger, isMutating } = useSWRMutation([page, limit], fetchData);

  useEffect(() => {
    trigger({ ...testDrive, page, limit });

    mutate();
  }, []);

  const columns = useMemo(() => {
    if (!testDrive) return [];

    const columnData = [...testDrive?.columns];

    const changeCol = columnData.map((col) => {
      const column = col;

      return column;
    });

    return changeCol;
  }, [testDrive]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;

      tempColumn.show = true;

      return tempColumn;
    });

    return global;
  }, [columns]);

  if (isLoading || !testDrive || isMutating) {
    return <MainLoading enter={isLoading || isMutating} bg="#000F3C4D" />;
  }

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isMutating} />

      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>

      <Header pageName="Test-Drive" />

      <CustomizedTable
        dataRows={testDrive?.data}
        dataColumns={columns}
        totalItems={testDrive?.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        loading={isLoading}
        onCreate={() => setIsShowCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...testDrive, page, limit })}
        colWithShow={globalColumn}
      />
    </>
  );
}
