import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const SpecificationField = ({
  index,
  field,
  onChange,
  onRemove,
  showRemoveButton,
}) => {
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="w-full flex gap-4 items-center">
        {/* Text Input for Name */}
        <div className="w-[300px]">
          <input
            type="text"
            placeholder="Name"
            className="w-full rounded-md border border-gray-300 p-2"
            value={field.name}
            onChange={(e) => onChange(index, "name", e.target.value)}
          />
        </div>

        {/* Remove Button */}
        {showRemoveButton && (
          <button
            className="w-4 h-4 rounded-full border border-slate-600 flex items-center justify-center"
            onClick={onRemove}
          >
            -
          </button>
        )}
      </div>

      {/* CKEditor for Data */}
      <div>
        <CKEditor
          editor={ClassicEditor}
          data={field.data}
          onChange={(event, editor) => {
            const content = editor.getData();
            onChange(index, "data", content);
          }}
        />
      </div>
    </div>
  );
};

export default SpecificationField;
