import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

const HookFormSelect = ({
  setValue,
  // register,
  selectedItems,
  option,
  errors,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSelect = (id) => {
    const updatedItems = selectedItems?.includes(id)
      ? selectedItems
      : [...selectedItems, id];

    setValue("other_edition", updatedItems);
  };

  const handleRemove = (id) => {
    const updatedItems = selectedItems?.filter((item) => item !== id);
    setValue("other_edition", updatedItems);
    setShowDropdown(true);
  };

  return (
    <div className="relative">
      <div
        className="w-full rounded-md border border-theme px-3 py-2 text-theme flex justify-between items-center"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div>
          {selectedItems?.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {selectedItems?.map((id) => {
                const item = option?.find((option) => option._id === id);
                return (
                  <span
                    key={id}
                    className={`flex items-center bg-blue-100 text-blue-700 px-2 py-1 rounded ${
                      item ? "block" : "hidden"
                    }`}
                  >
                    {item?.edition_name}
                    <button
                      type="button"
                      className="ml-2 text-red-500 hover:text-red-700"
                      onClick={() => handleRemove(id)}
                    >
                      &times;
                    </button>
                  </span>
                );
              })}
            </div>
          ) : (
            <span className="text-gray-400">Select editions...</span>
          )}
        </div>

        <div className="absolute top-4 right-4 cursor-pointer">
          <FiChevronDown onClick={() => setShowDropdown(!showDropdown)} />
        </div>
      </div>

      {showDropdown && (
        <div className="absolute z-10 mt-1 w-full rounded-md border border-gray-300 bg-white shadow-lg">
          {option?.map((option) => (
            <div
              key={option._id}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect(option._id)}
            >
              {option.edition_name}
            </div>
          ))}
        </div>
      )}

      {/* Hidden input for React Hook Form */}
      <input
        type="hidden"
        // {...register("other_edition", { required: true })}
        value={selectedItems}
      />

      {/* {selectedItems?.length === 0 &&
        errors.other_edition &&
        errors?.other_edition?.type === "required" && (
          <span className="text-red-500 text-sm mt-2">
            This field is required.
          </span>
        )} */}
    </div>
  );
};

export default HookFormSelect;
