import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const BannerTipsField = ({
  index,
  field,
  onChange,
  onRemove,
  showRemoveButton,
}) => {
  return (
    <div className="flex flex-col w-full gap-8">
      <div className="w-full flex gap-4 items-center">
        {/* Text Input for Key */}
        <div className="w-[300px]">
          <input
            type="text"
            placeholder="Key"
            className="w-full rounded-md border border-theme px-3 py-2 text-theme"
            value={field.key}
            onChange={(e) => onChange(index, "key", e.target.value)}
          />
        </div>
        {/* Text Input for Value */}
        <div className="w-[300px]">
          <input
            type="text"
            placeholder="Value"
            className="w-full rounded-md border border-theme px-3 py-2 text-theme"
            value={field.value}
            onChange={(e) => onChange(index, "value", e.target.value)}
          />
        </div>

        {/* Remove Button */}
        {showRemoveButton && (
          <button
            className={`w-6 h-6 rounded-full shadow-md flex items-center justify-center text-center border border-[#2962ff] hover:bg-[#2962ff] text-black hover:text-white `}
            onClick={onRemove}
          >
            <span className="text-[18px] font-bold">-</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default BannerTipsField;
