import React, { useState, useContext, useEffect, useMemo } from "react";
import FormModal from "../../../src/components/FormModal";
import VehicleForm from "../../components/Forms/VehicleForm";
import axiosClient from "../../axios-client";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import ActionComponent from "../../../src/components/Table/Components/ActionComponent";

import CustomAlert from "../../../src/components/Notification/CustomAlert";

import LoadingComponent from "../../../src/components/Loading";

import CustomizedTable from "../../../src/components/Table/CustomizedTable";
import CustomizeManualCell from "../../../src/components/Table/Components/CustomizeManualCell";
import MainLoading from "../../../src/components/MainLoading";

import { ErrContext } from "../../providers/ErrorProvider";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../components/PageHeader";
import "react-toastify/dist/ReactToastify.css";

const Vehicle = () => {
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);
  const [alertState, setAlertState] = useState({
    open: false,

    message: "",

    type: "",

    btnLabel: "",

    btnColor: "",

    onBtnClick: null,
  });
  const [setIsError, setErrMsg] = useContext(ErrContext);

  const fetcher = async (url) => {
    try {
      const response = await axiosClient.get(url);
      return response?.data;
    } catch (err) {
      console.error("Error fetching banners:", err);
      return undefined;
    }
  };

  const {
    data: vehicles,
    mutate,
    isLoading,
  } = useSWR(`/backpanel/vehicle`, fetcher);

  const { trigger, isMutating } = useSWRMutation([page, limit], fetcher);

  useEffect(() => {
    trigger({ ...vehicles, page, limit });

    mutate();
  }, []);

  const defaultData = {
    _id: "",
    banner_image: "",
    banner_title: "",
    banner_subtitle: "",
    banner_tips: [],
    test_drive_image: "",
    default_edition: "",
    other_edition: [],
    vehicle_name: "",
    is_manual: false,
    videofile: "",
    intro_image: "",
    publish: false,
  };

  const [formData, setFormData] = useState(defaultData);

  const createVehicle = async (
    hookFormData,
    newImages,
    bannerTips,
    specifications
  ) => {
    const formData = new FormData();

    if (newImages.length != 0) {
      for (let i = 0; i < newImages?.length; i++) {
        formData.append("imagefile", newImages[i]);
      }
    }

    formData.append("publish", hookFormData?.publish);
    formData.append("order", hookFormData?.order);

    formData.append("banner_image", hookFormData?.banner_image);

    formData.append("banner_title", hookFormData?.title);

    formData.append("banner_subtitle", hookFormData?.subtitle);

    formData.append("banner_tips", JSON.stringify(bannerTips));

    formData.append("test_drive_image", hookFormData?.test_drive_image);

    formData.append("default_edition", hookFormData?.default_edition);

    formData.append(
      "other_edition",
      JSON.stringify(hookFormData?.other_edition)
    );

    if (hookFormData?.videofile)
      formData.append("videofile", hookFormData?.videofile);

    if (hookFormData?.intro_image)
      formData.append("intro_image", hookFormData?.intro_image);

    try {
      const response = await axiosClient?.post(`/backpanel/vehicle`, formData);
      toast(response?.data?.message, { type: "success" });
      // await mutate();
      if (response) {
        setIsShowCreate(false);
      }
      mutate();
      return response;
    } catch (err) {
      toast(err?.response?.data?.message || "Something went wrong", {
        type: "error",
      });
      return err;
    }
  };

  const editData = async (row) => {
    const tableData = {
      _id: row._id,
      publish: row.publish,
      order: row.order,
      banner_image: row.banner_image,
      banner_title: row.banner_title,
      banner_subtitle: row.banner_subtitle,
      banner_tips: row.banner_tips,
      test_drive_image: row.test_drive_image,
      default_edition: row.default_edition._id,
      other_edition: row.other_edition.map((edition, i) => edition),
      vehicle_name: row.vehicle_name,
      is_manual: row.is_manual,
      videofile: row.videofile,
      intro_image: row.intro_image,
    };
    setFormData(tableData);
    setIsShowEdit(true);
  };

  let removeImages = [];
  const updateVehicle = async (
    hookFormData,
    newImages,
    bannerTips,
    specifications
  ) => {
    const payloadData = new FormData();

    if (newImages.length != 0) {
      for (let i = 0; i < newImages?.length; i++) {
        payloadData.append("imagefile", newImages[i]);
      }
    }

    payloadData.append("publish", hookFormData?.publish);
    payloadData.append("order", hookFormData?.order);

    if (hookFormData.banner_image)
      payloadData.append("banner_image", hookFormData.banner_image);

    payloadData.append("banner_title", hookFormData.title);

    payloadData.append("banner_subtitle", hookFormData.subtitle);

    payloadData.append("banner_tips", JSON.stringify(bannerTips));

    if (hookFormData.test_drive_image)
      payloadData.append("test_drive_image", hookFormData.test_drive_image);

    payloadData.append("default_edition", hookFormData.default_edition);

    payloadData.append(
      "other_edition",
      JSON.stringify(hookFormData.other_edition)
    );

    if (hookFormData.videofile)
      payloadData.append("videofile", hookFormData.videofile);

    if (hookFormData.intro_image)
      payloadData.append("intro_image", hookFormData.intro_image);

    if (hookFormData.test_drive_image)
      removeImages.push(formData.test_drive_image);

    if (hookFormData.banner_image) removeImages.push(formData.banner_image);

    if (hookFormData.intro_image) removeImages.push(formData.intro_image);
    payloadData.append(
      "removeImage",
      removeImages ? JSON.stringify(removeImages) : JSON.stringify([])
    );

    try {
      const response = await axiosClient.put(
        `/backpanel/vehicle/${formData._id}`,
        payloadData
      );
      toast(response?.data?.message, { type: "success" });
      await mutate();
      if (response) {
        setIsShowEdit(false);
      }
      setFormData({
        ...defaultData,
      });
      return response;
    } catch (err) {
      toast(err?.response?.data?.message || "Something went wrong", {
        type: "error",
      });
      return err;
    }
  };

  const deleteData = async (row) => {
    setAlertState({
      ...alertState,

      open: false,
    });

    await axiosClient
      .delete(`/backpanel/vehicle/${row?._id}`)

      .then((response) => {
        toast(response.data.message, { type: "success" });
      })

      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    mutate();
    await trigger({ ...vehicles, page, limit });
  };

  const columns = useMemo(() => {
    if (!vehicles) return [];

    const columnData = [
      {
        Header: "Action",

        accessor: "action",

        filter: false,

        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),

        width: 100,
      },

      ...vehicles?.columns,
    ];

    const changeCol = columnData?.map((col) => {
      const column = col;

      if (column.accessor === "is_manual") {
        column.Cell = ({ cell }) => CustomizeManualCell({ cell });

        column.width = 50;
      }

      return column;
    });

    return changeCol;
  }, [vehicles]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;

      tempColumn.show = true;

      return tempColumn;
    });

    return global;
  }, [columns]);

  if (isLoading || !vehicles || isMutating) {
    return <MainLoading enter={isLoading || isMutating} bg="#000F3C4D" />;
  }
  return (
    <div>
      <MainLoading bg="#000F3C4D" enter={isMutating} />

      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>
      <Header pageName="Build Vehicle" />
      <CustomizedTable
        dataRows={vehicles?.data}
        dataColumns={columns}
        totalItems={vehicles.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        loading={isLoading}
        onCreate={() => setIsShowCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...news, page, limit })}
        colWithShow={globalColumn}
      />
      <FormModal isOpen={isShowCreate}>
        <VehicleForm
          type="createForm"
          formData={formData}
          onClose={() => setIsShowCreate(false)}
          onConfirm={(e, newImages, bannerTips, specifications) =>
            createVehicle(e, newImages, bannerTips, specifications)
          }
        />
      </FormModal>
      <FormModal isOpen={isShowEdit}>
        <VehicleForm
          type="editForm"
          formData={formData}
          onClose={() => setIsShowEdit(false)}
          onConfirm={(e, newImages, bannerTips, specifications) =>
            updateVehicle(e, newImages, bannerTips, specifications)
          }
        />
      </FormModal>
      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      />
    </div>
  );
};

export default Vehicle;
