/* eslint-disable no-underscore-dangle */
import _ from "lodash";

import React, { useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import CustomizedTable from "../../components/Table/CustomizedTable";
import FormModal from "../../components/FormModal";

// import ContactForm from "../../components/Forms/ContactForm";
import LoadingComponent from "../../components/Loading";
import CustomAlert from "../../components/Notification/CustomAlert";
import MainLoading from "../../components/MainLoading/index";
import "react-toastify/dist/ReactToastify.css";
import ActionComponent from "../../components/Table/Components/ActionComponent";
import CustomizePublishCell from "../../components/Table/Components/CustomizePublishCell";
import CustomizedImageCell from "../../components/Table/Components/CustomizedImageCell";
import clearSearch from "../../components/Table/Components/utils";
// import { ErrContext } from "../../../providers/ErrorProvider";
import CustomizeIsremoteCell from "../../components/Table/Components/CustomizeIsremoteCell";

import axiosClient from "../../axios-client";

export default function Page() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    type: "",
    btnLabel: "",
    btnColor: "",
    onBtnClick: null,
  });

  const defaultData = {
    imagefile: "",
    name: "",
    address: "",
    phone: "",
    publish: false,
  };

  const [formData, setFormData] = useState(defaultData);
  //   const [setIsError, setErrMsg] = useContext(ErrContext);

  const fetchData = async ([pageParams, limitParams]) => {
    let resp;

    resp = await axiosClient
      .get(`/backpanel/dealer?page=${pageParams}&limit=${limitParams}`)
      .then((response) => response?.data)
      .catch((err) => {
        //   setIsError(true);
        //   setErrMsg({ code: err.response.status, msg: err.message });
      });

    return resp;
  };

  const { data, isLoading } = useSWR([page, limit], fetchData);
  const { trigger, isMutating } = useSWRMutation([page, limit], fetchData);

  const addContact = async (body) => {
    const formData = new FormData();

    // formData.append("salary", body.salary);
    // formData.append("imagefile", body.imagefile);

    // formData.append("isremote", body.isremote);
    // formData.append("description", body.description);

    // formData.append("hrphone", body.hrphone);
    // formData.append("position", body.position);

    // formData.append("post", body.post);
    // formData.append("publish", body.publish);
    // formData.append("hremail", body.hremail);

    // formData.append("title", body.title);
    // formData.append("type", body.type);

    await axiosClient
      .post(`/backpanel/dealer`, formData)
      .then(async (res) => {
        await trigger({ ...data, page, limit, filterData });
        setIsOpenCreate(false);
        toast(res.data.message, { type: "success" });
      })
      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    return null;
  };

  const editData = async (row) => {
    const tableData = {
      _id: row._id,
      imagefile: row.imagefile,
      name: row.name,
      address: row.address,
      phone: row.phone,
      publish: row.publish,
    };
    setFormData(tableData);
    setIsOpenEdit(true);
  };

  const deleteData = async (row) => {
    setAlertState({
      ...alertState,
      open: false,
    });
    await axiosClient
      .delete(`/backpanel/dealer/${row._id}`)
      .then((response) => {
        toast(response.data.message, { type: "success" });
      })
      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    await trigger({ ...data, page, limit, filterData });
  };

  const updateContact = async (body, id) => {
    await axiosClient
      .put(`/backpanel/dealer/${id}`, body)
      .then(async (res) => {
        await trigger({ ...data, page, limit, filterData });
        setIsOpenEdit(false);
        toast(res.data.message, { type: "success" });
      })
      .catch((error) => {
        toast(error?.response?.data?.message || "Something went wrong", {
          type: "error",
        });
      });
    setFormData({
      ...formData,
    });
  };

  const columns = useMemo(() => {
    if (!data) return [];
    const columnData = [
      {
        Header: "Action",
        accessor: "action",
        filter: false,
        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),
        width: 100,
      },
      ...data.columns,
    ];
    const changeCol = columnData.map((col) => {
      const column = col;

      if (column.accessor === "publish") {
        column.Cell = ({ cell }) => CustomizePublishCell({ cell });
      }
      if (column.accessor?.includes("imagefile")) {
        column.Cell = ({ cell }) => CustomizedImageCell({ cell });

        column.width = 200;
      }

      return column;
    });
    return changeCol;
  }, [data]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;
      tempColumn.show = true;
      return tempColumn;
    });
    return global;
  }, [columns]);

  if (isLoading || !data || isMutating) {
    return <MainLoading enter={isLoading || isMutating} bg="#000F3C4D" />;
  }

  return (
    <>
      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>

      <CustomizedTable
        dataRows={data?.data}
        dataColumns={columns}
        totalItems={data?.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        loading={isLoading}
        onCreate={() => setIsOpenCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...data, page, limit })}
        colWithShow={globalColumn}
      />
      {/* <FormModal isOpen={isOpenCreate} header="Create Contact">
        <ContactForm
          id="create_form"
          previewId="previewDialog"
          onClose={() => {
            setIsOpenCreate(false);
          }}
          onConfirm={(formdata) => addContact(formdata)}
        />
      </FormModal>
      <FormModal
        isOpen={isOpenEdit}
        onClose={() => {
          setIsOpenEdit(false);
        }}
        header="Edit Contact"
      >
        <ContactForm
          id="edit_form"
          previewId="previewDialog"
          formData={formData}
          onClose={() => {
            setFormData({
              ...defaultData,
            });
            setIsOpenEdit(false);
          }}
          onConfirm={(updateData) => {
            const data = new FormData();

            data.append("title", updateData.title);
            data.append("position", updateData.position);
            data.append("description", updateData.description);

            data.append("post", updateData.post);
            data.append("salary", updateData.salary);

            data.append("type", updateData.type);
            data.append("isremote", updateData.isremote);

            data.append("hremail", updateData.hremail);

            data.append("hrphone", updateData.hrphone);
            data.append("publish", updateData.publish);

            if (updateData.imagefile)
              data.append("imagefile", updateData.imagefile);

            data.append("removeImage", JSON.stringify([]));

            updateContact(data, updateData?._id);
          }}
        />
      </FormModal>
      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      /> */}
    </>
  );
}
