import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../axios-client";
import { useForm, Controller } from "react-hook-form";
import styles from "./style.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GiCancel } from "react-icons/gi";
import SpecificationField from "../Inputs/VehicleSpecificationFormInput";
import PrimaryButton from "../Buttons/PrimaryButton";

function EditionForm({ type, onConfirm, onClose, formData }) {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [models, setModels] = useState([]);
  const [specificationFile, setSpecificationFile] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);
  const [colorNewImages, setColorNewImages] = useState([]);
  const [colorExistingImages, setColorExistingImages] = useState([]);
  const [exteriorNewImages, setExteriorNewImages] = useState([]);
  const [exteriorExistingImages, setExteriorExistingImages] = useState([]);
  const [interiorNewImages, setInteriorNewImages] = useState([]);
  const [interiorExistingImages, setInteriorExistingImages] = useState([]);
  const [demoNewImages, setDemoNewImages] = useState([]);
  const [demoExistingImages, setDemoExistingImages] = useState([]);
  const [err, setErr] = useState();
  const [specifications, setSpecifications] = useState([
    { name: "", data: "" },
  ]);
  const [data, setData] = useState({
    model_id: "",
    edition_name: "",
    edition_description: { title: "", subtitle: "", description: "" },
    color:
      type === "editForm"
        ? [{ color_name: "", color_code: "#FFFFFF", images: [], _id: "" }]
        : [{ color_name: "", color_code: "#FFFFFF", images: [] }],
    interior: { title: "", images: [] },
    exterior: { title: "", images: [] },
    imagefile: [],
    rate: "",
    is_manual: false,
    type: "",
    demoImages: [],
  });

  const titleRef = useRef();
  const subtitleRef = useRef();
  const descriptionRef = useRef();
  const exteriorTitleRef = useRef();
  const interiorTitleRef = useRef();
  const {
    watch,
    register,
    setValue,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {
      edition_name: type === "editForm" ? formData?.edition_name : "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    setEditorLoaded(true);
    const fetchModels = async () => {
      try {
        const response = await axiosClient.get("/backpanel/model");
        setModels(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };
    const fetchSpecificationFile = async () => {
      try {
        const response = await axiosClient.get("/backpanel/file");
        setSpecificationFile(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };
    fetchModels();
    fetchSpecificationFile();
  }, []);

  const newFormAdded = () => {
    const newColor = { color_name: "", color_code: "#FFFFFF", images: [] };
    setData((prevData) => ({
      ...prevData,
      color: [...prevData.color, newColor],
    }));
  };

  const validateImages = () => {
    if (exteriorNewImages.length < 4 && interiorNewImages < 4) {
      setErr("images must be at least 4.");
    } else if (exteriorNewImages.length !== interiorNewImages.length) {
      setErr("Exterior and interior images must have the same number.");
    } else {
      setErr("");
    }
  };
  const validationRules = {
    1: (data) =>
      data?.model_id &&
      data?.edition_name &&
      data?.edition_description?.title &&
      data?.edition_description?.subtitle &&
      data?.edition_description?.description,
    2: (data) =>
      Array.isArray(data?.color) &&
      data.color.every(
        (c) =>
          c.color_name?.trim() &&
          c.color_code?.startsWith("#") &&
          Array.isArray(c.images) &&
          (c?._id?.length || c.images.length > 0)
      ),
    3: (data) =>
      data?.exterior?.title?.trim() &&
      Array.isArray(data.exterior.images) &&
      (data?._id?.length ||
        exteriorExistingImages.length + exteriorNewImages.length >= 4 ||
        exteriorNewImages.length >= 4 ||
        exteriorExistingImages >= 4),
    4: (data) =>
      data?.interior?.title?.trim() &&
      Array.isArray(data.interior.images) &&
      (data?._id?.length ||
        interiorExistingImages.length + interiorNewImages.length >= 4 ||
        interiorNewImages.length >= 4 ||
        interiorExistingImages >= 4),
    5: (data) => data?.is_manual && data?.rate && data?.type && specifications,
  };

  const handleBack = () => {
    if (step > 0) {
      const previousStep = step - 1;
      setStep(previousStep);
      setCompletedSteps((prev) => prev.filter((s) => s !== step));
    }
  };
  const isValid = validationRules[step](data);

  useEffect(() => {
    if (step === 5) {
      setIsCreateDisabled(!isValid);
    } else {
      setIsNextDisabled(!isValid);
    }
  }, [isValid]);

  const handleNext = () => {
    // const isValid = validationRules[step](data);
    if (isValid) {
      const nextStep = step + 1;
      setStep(nextStep);
      if (!completedSteps.includes(nextStep)) {
        setCompletedSteps((prev) => [...prev, nextStep]);
      }
    }
  };

  const handleRemoveColor = (index) => {
    setData((prev) => ({
      ...prev,
      color: prev.color.filter((_, i) => i !== index),
    }));
  };

  const onUpload = (e, type, colorIndex) => {
    const filesArray = Array.from(e.target.files);
    const files = e.target.files;

    if (type === "color") {
      setColorNewImages((prev) => {
        const updatedNewImages = [...prev];
        updatedNewImages[colorIndex] = [
          ...(updatedNewImages[colorIndex] || []),
          ...filesArray,
        ];
        return updatedNewImages;
      });
    }
    if (type === "exterior") {
      console.log("previous images", exteriorExistingImages);
      console.log(filesArray, "nwe ");
      setExteriorNewImages((prevImages) => {
        const updatedImages = [...prevImages, ...filesArray];

        return updatedImages;
      });
    }
    if (type === "interior") {
      setInteriorNewImages((prevImages) => [...prevImages, ...filesArray]);
    }

    if (type === "demo") {
      setDemoNewImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  useEffect(() => {
    const allNewImages = [
      ...colorNewImages.flat(),
      ...exteriorNewImages.flat(),
      ...interiorNewImages.flat(),
      ...demoNewImages.flat(),
    ];
    validateImages();
    setData((prevData) => ({
      ...prevData,
      imagefile: allNewImages,
      demoImages:
        demoNewImages?.length > 0
          ? demoNewImages?.flat()?.map((file) => file.name)
          : [],
      color: prevData.color.map((c, i) => ({
        ...c,
        images: colorNewImages?.[i]?.map((file) => file.name) || [],
      })),
      exterior: {
        ...prevData.exterior,
        images: exteriorNewImages?.flat()?.map((file) => file.name) || [],
      },
      interior: {
        ...prevData.interior,
        images: interiorNewImages?.flat()?.map((file) => file.name) || [],
      },
    }));
  }, [colorNewImages, exteriorNewImages, interiorNewImages, demoNewImages]);

  const handleRemoveNewImage = (type, colorIndex, imageIndex) => {
    if (type === "color") {
      setColorNewImages((prevImages) => {
        const updatedImages = [...prevImages];
        if (updatedImages[colorIndex]) {
          updatedImages[colorIndex] = updatedImages[colorIndex].filter(
            (_, i) => i !== imageIndex
          );
        }
        return updatedImages;
      });
    }
    if (type === "exterior") {
      setExteriorNewImages((prevImages) =>
        prevImages.filter((_, i) => i !== imageIndex)
      );
    }

    if (type === "interior") {
      setInteriorNewImages((prevImages) =>
        prevImages.filter((_, i) => i !== imageIndex)
      );
    }

    if (type === "demo") {
      setDemoNewImages((prevImages) =>
        prevImages.filter((_, i) => i !== imageIndex)
      );
    }
  };

  const handleRemoveExistingImage = (type, colorIndex, image, imageIndex) => {
    if (type === "color") {
      setColorExistingImages((prevImages) => {
        const updatedImages = [...prevImages];
        if (updatedImages[colorIndex]) {
          updatedImages[colorIndex] = updatedImages[colorIndex].filter(
            (_, i) => i !== imageIndex
          );
        }
        return updatedImages;
      });
    }

    if (type === "exterior") {
      setExteriorExistingImages((prevImages) =>
        prevImages.filter((_, i) => i !== imageIndex)
      );
    }
    if (type === "interior") {
      setInteriorExistingImages((prevImages) =>
        prevImages.filter((_, i) => i !== imageIndex)
      );
    }
    if (type === "demo") {
      setDemoExistingImages((prevImages) =>
        prevImages.filter((_, i) => i !== imageIndex)
      );
    }
    setRemoveImages((prevImages) => [...prevImages, image]);
  };

  //specification
  const handleSpecificationAddition = () => {
    setSpecifications((prev) => [...prev, { name: "", data: "" }]);
  };

  const handleSpecificationReduceField = (index) => {
    setSpecifications((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSpecificationChange = (index, field, value) => {
    setSpecifications((prev) =>
      prev.map((spec, i) => (i === index ? { ...spec, [field]: value } : spec))
    );
  };

  //get old values
  useEffect(() => {
    // first wizard
    if (type === "editForm" && formData?.edition_name) {
      setData((prevState) => ({
        ...prevState,
        edition_name: formData.edition_name,
      }));
      setValue("edition_name", formData.edition_name);
    }

    if (type === "editForm" && formData?.model_id) {
      setData((prevState) => ({
        ...prevState,
        model_id: formData?.model_id?._id,
      }));
      setValue("model_id", formData.model_id?._id);
    }

    if (type === "editForm" && formData?.edition_description) {
      setData((prevState) => ({
        ...prevState,
        edition_description: {
          ...prevState.edition_description,
          title: formData.edition_description.title,
          subtitle: formData.edition_description.subtitle,
          description: formData.edition_description.description,
        },
      }));
      setValue("title", formData.edition_description.title);
      setValue("subtitle", formData.edition_description.subtitle);
      setValue("description", formData.edition_description.description);
    }

    //second wizard
    if (type === "editForm" && formData?.color) {
      setColorExistingImages(formData.color.map((color) => color.images || []));

      setData((prevData) => ({
        ...prevData,
        color: formData.color.map((color) => ({
          color_name: color.color_name,
          color_code: color.color_code,
          images: [...color.images],
          _id: color._id,
        })),
      }));
    }

    // third wizard
    if (type === "editForm" && formData?.exterior) {
      setExteriorExistingImages(formData?.exterior?.images);
      setData((prevState) => ({
        ...prevState,
        exterior: {
          title: formData?.exterior?.title || "",
          images: formData?.exterior?.images || [],
        },
      }));
      setValue("exterior.title", formData?.exterior?.title || "");
      setValue("exterior.images", formData?.exterior?.images || []);
    }
    //fourth wizard
    if (type === "editForm" && formData?.interior) {
      setInteriorExistingImages(formData.interior.images);
      setData((prevState) => ({
        ...prevState,
        interior: {
          title: formData?.interior?.title || "",
          images: formData?.interior?.images || [],
        },
      }));
      setValue("interior.title", formData?.interior?.title || "");
      setValue("interior.images", formData?.interior?.images || []);
    }
    //fifth wizard
    if (type === "editForm" && formData?.rate) {
      setData((prevState) => ({
        ...prevState,
        rate: formData?.rate,
      }));
      setValue("rate", formData?.rate);
    }
    if (type === "editForm" && formData?.type) {
      setData((prevState) => ({
        ...prevState,
        type: formData?.type,
      }));
    }
    if (type === "editForm" && formData?.is_manual) {
      setData((prevState) => ({
        ...prevState,
        is_manual: formData?.is_manual,
      }));
    }
    if (type === "editForm" && formData?.specification) {
      setSpecifications(
        formData?.specification.map((s) => ({ name: s.name, data: s.data }))
      );
    }

    if (type === "editForm" && formData?.specification_file) {
      setData((prevState) => ({
        ...prevState,
        specification_file: formData?.specification_file?._id,
      }));
      setValue("specification_file", formData.specification_file?._id);
    }

    if (type === "editForm" && formData?.demo_images) {
      setDemoExistingImages(formData?.demo_images);
      // setData((prevState) => ({
      //   ...prevState,
      //   demoImages: formData?.demo_images || [],
      // }));
      setValue("demoImages", formData?.demo_images || []);
    }
  }, [formData, type, setValue]);
  return (
    <div className="px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] bg-white px-7 py-7 shadow-md">
        {step === 1 && (
          <>
            <div className="flex space-x-4 items-center w-full">
              {/* Model lists */}
              <div className="w-1/2">
                <select
                  id="model_id"
                  {...register("model_id", { required: "Model is required" })}
                  value={watch("model_id")}
                  className="block border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm min-w-[200px] rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme"
                  onChange={(e) => {
                    setValue("model_id", e.target.value);
                    setData((prevData) => ({
                      ...prevData,
                      model_id: e.target.value,
                    }));
                  }}
                >
                  <option value="">-- Select Model --</option>
                  {models.map((model) => (
                    <option key={model._id} value={model._id}>
                      {model.model_name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Edition name */}
              <div className="w-1/2">
                <input
                  type="text"
                  placeholder="Edition Name"
                  className="w-full rounded-md border border-gray-300 p-2"
                  value={data.edition_name || ""}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      edition_name: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            {/* edition description */}
            <div className="justify-center font-bold text-center">
              Edition Description
            </div>
            <hr />
            {/* title */}
            <div className="">
              {" "}
              <div className={styles.labelStyle} htmlFor="title">
                {" "}
                <div className="mb-2">English</div>
                Title :{" "}
              </div>
              <div className="">
                {editorLoaded ? (
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <CKEditor
                        ref={titleRef}
                        editor={ClassicEditor}
                        id="rich-text-editor"
                        data={field.value || ""}
                        onReady={(editor) => {
                          editor?.editing?.view?.change((writer) => {
                            writer?.setStyle(
                              "height",
                              "200px",
                              editor?.editing?.view?.document?.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor?.getData();
                          field.onChange(data);
                          setValue("title", data);
                          setData((prevState) => ({
                            ...prevState,
                            edition_description: {
                              ...prevState.edition_description,
                              title: data,
                            },
                          }));
                        }}
                      />
                    )}
                  />
                ) : (
                  <div>Editor Loading</div>
                )}
                {errors?.title && (
                  <p className="mt-2 text-red-500">{errors.title.message}</p>
                )}
              </div>
            </div>

            {/* subtitle */}
            <div className="">
              {" "}
              <div className={styles.labelStyle} htmlFor="subtitle">
                {" "}
                <div className="mb-2">English</div>
                Subtitle :{" "}
              </div>
              <div className="">
                {editorLoaded ? (
                  <Controller
                    name="subtitle"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <CKEditor
                        ref={subtitleRef}
                        editor={ClassicEditor}
                        id="rich-text-editor"
                        data={field.value || ""}
                        onReady={(editor) => {
                          editor?.editing?.view?.change((writer) => {
                            writer?.setStyle(
                              "height",
                              "200px",
                              editor?.editing?.view?.document?.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor?.getData();
                          field.onChange(data);
                          setValue("subtitle", data);
                          setData((prevState) => ({
                            ...prevState,
                            edition_description: {
                              ...prevState.edition_description,
                              subtitle: data,
                            },
                          }));
                        }}
                      />
                    )}
                  />
                ) : (
                  <div>Editor Loading</div>
                )}
                {errors?.subtitle && (
                  <p className="mt-2 text-red-500">{errors.subtitle.message}</p>
                )}
              </div>
            </div>

            {/* description */}
            <div className="">
              {" "}
              <div className={styles.labelStyle} htmlFor="description">
                {" "}
                <div className="mb-2">English</div>
                Description :{" "}
              </div>
              <div className="">
                {editorLoaded ? (
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <CKEditor
                        ref={descriptionRef}
                        editor={ClassicEditor}
                        id="rich-text-editor"
                        data={field.value || ""}
                        onReady={(editor) => {
                          editor?.editing?.view?.change((writer) => {
                            writer?.setStyle(
                              "height",
                              "200px",
                              editor?.editing?.view?.document?.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor?.getData();
                          field.onChange(data);
                          setValue("description", data);
                          setData((prevState) => ({
                            ...prevState,
                            edition_description: {
                              ...prevState.edition_description,
                              description: data,
                            },
                          }));
                        }}
                      />
                    )}
                  />
                ) : (
                  <div>Editor Loading</div>
                )}
                {errors?.description && (
                  <p className="mt-2 text-red-500">
                    {errors.description.message}
                  </p>
                )}
              </div>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            {" "}
            <div className="rounded-sm shadow-lg border-gray-600 border-2 p-3 mt-4">
              <button
                onClick={() => newFormAdded()}
                className="w-[30px] h-[30px] items-center border"
              >
                +
              </button>
              <div className="w-full space-y-5 laptop:space-y-0">
                <div className={styles.inputWrapper}>
                  {data?.color?.map((color, colorIndex) => (
                    <div className="mt-4">
                      {/* <button onClick={() => handleRemoveColor(colorIndex)}>
                        -
                      </button> */}
                      {colorIndex > 0 && (
                        <button
                          onClick={() => handleRemoveColor(colorIndex)}
                          className="w-[30px] h-[30px] items-center border"
                        >
                          -
                        </button>
                      )}
                      <div className={styles.label}>Color Name *</div>
                      <div className="flex space-x-4 items-center ">
                        {/* Color Name */}
                        <div className="flex-1">
                          <input
                            type="text"
                            value={color.color_name || ""}
                            onChange={(e) => {
                              const updatedColor = [...data.color];
                              updatedColor[colorIndex] = {
                                ...updatedColor[colorIndex],
                                color_name: e.target.value,
                              };
                              setData((prevData) => ({
                                ...prevData,
                                color: updatedColor,
                              }));
                            }}
                            placeholder="Enter color name"
                            className="w-full rounded-md border border-gray-300 p-2"
                          />
                        </div>

                        {/* Color Code */}
                        <div className="flex items-center space-x-4">
                          {/* Color Picker */}
                          <input
                            type="color"
                            className="w-12 h-12 border rounded"
                            value={
                              data?.color[colorIndex]?.color_code || "#FFFFFF"
                            }
                            onChange={(e) => {
                              const updatedColor = [...data.color];
                              updatedColor[colorIndex] = {
                                ...updatedColor[colorIndex],
                                color_code: e.target.value,
                              };
                              setData((prevData) => ({
                                ...prevData,
                                color: updatedColor,
                              }));
                            }}
                          />

                          {/* Hexadecimal Value Input */}
                          <input
                            type="text"
                            className="w-28 px-2 py-1 border rounded"
                            value={
                              data.color[colorIndex]?.color_code || "#FFFFFF"
                            }
                            readOnly
                            placeholder="#FFFFFF"
                          />
                        </div>
                      </div>

                      {/* Upload Color Image */}
                      <div
                        key={colorIndex}
                        className="w-full flex flex-col items-center gap-4"
                      >
                        <span className="text-yellow-400 text-sm font-semibold w-full">
                          * Color image must be transparent and only one image.
                        </span>
                        <label
                          htmlFor={`img_input_${colorIndex}`}
                          className={styles.img_label}
                        >
                          Upload Color Images{" "}
                          <input
                            id={`img_input_${colorIndex}`}
                            className={styles.img_input}
                            multiple
                            type="file"
                            accept="image/*"
                            onChange={(e) => onUpload(e, "color", colorIndex)}
                          />
                        </label>

                        <div
                          className={`flex flex-row h-[200px] w-full overflow-hidden overflow-x-auto border border-dashed border-[#000f3c]`}
                        >
                          {/* Map existing images for this color */}
                          {colorExistingImages.length > 0 &&
                            (colorExistingImages[colorIndex] || []).map(
                              (image, index) => (
                                <div
                                  key={`existing-${colorIndex}-${index}`}
                                  className="flex flex-col relative items-center mr-2"
                                >
                                  <img
                                    src={`/bucket/motor${image}`}
                                    alt={`Preview Existing ${index + 1}`}
                                    style={{
                                      minWidth: "200px",
                                      width: "200px",
                                      height: "200px",
                                      objectFit: "cover",
                                    }}
                                  />

                                  <button
                                    className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                                    onClick={() =>
                                      handleRemoveExistingImage(
                                        "color",
                                        colorIndex,
                                        image,
                                        index
                                      )
                                    }
                                  >
                                    <GiCancel size={12} />
                                  </button>
                                </div>
                              )
                            )}

                          {/* Map new images for this color */}
                          {colorNewImages.length > 0 &&
                            (colorNewImages[colorIndex] || []).map(
                              (image, index) => (
                                <div
                                  key={`new-${colorIndex}-${index}`}
                                  className="flex flex-col relative items-center mr-2"
                                >
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview New ${index + 1}`}
                                    style={{
                                      minWidth: "200px",
                                      width: "200px",
                                      height: "200px",
                                      objectFit: "cover",
                                    }}
                                  />

                                  <button
                                    className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                                    onClick={() =>
                                      handleRemoveNewImage(
                                        "color",
                                        colorIndex,
                                        index
                                      )
                                    }
                                  >
                                    <GiCancel size={12} />
                                  </button>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            {" "}
            <div className="rounded-sm shadow-lg border-gray-600 border-2 p-3 mt-4">
              {/*exterior title */}
              <div className="">
                {" "}
                <div className={styles.labelStyle} htmlFor="exterior">
                  {" "}
                  Exterior Title :{" "}
                </div>
                <div className="">
                  {editorLoaded ? (
                    <Controller
                      name="exterior.title"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <CKEditor
                          ref={interiorTitleRef}
                          editor={ClassicEditor}
                          id="rich-text-editor"
                          data={field.value || ""}
                          onReady={(editor) => {
                            editor?.editing?.view?.change((writer) => {
                              writer?.setStyle(
                                "height",
                                "200px",
                                editor?.editing?.view?.document?.getRoot()
                              );
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor?.getData();
                            field.onChange(data);
                            setData((prevState) => ({
                              ...prevState,
                              exterior: {
                                ...prevState.interior,
                                title: data,
                              },
                            }));
                          }}
                        />
                      )}
                    />
                  ) : (
                    <div>Editor Loading</div>
                  )}
                  {errors?.exterior?.title && (
                    <p className="mt-2 text-red-500">
                      {errors.exterior.title.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Upload Exterior Images */}
              <div className="w-full flex flex-col items-center gap-4">
                <label htmlFor="img_input" className={styles.img_label}>
                  Upload Exterior Images
                  <input
                    id="img_input"
                    className={styles.img_input}
                    multiple
                    type="file"
                    accept="image/*"
                    onChange={(e) => onUpload(e, "exterior")}
                  />
                </label>
                {err && <p className="mt-2 text-red-500">{err}</p>}
                <div
                  className={`flex flex-row h-[200px] w-full overflow-hidden overflow-x-auto  border border-dashed border-[#000f3c]`}
                >
                  {exteriorExistingImages?.map((image, index) => (
                    <div
                      key={index}
                      className="flex flex-col relative items-center mr-2"
                    >
                      <img
                        src={`/bucket/motor${image}`}
                        alt={`Preview ${index + 1}`}
                        style={{
                          minWidth: "200px",
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />

                      <button
                        className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                        onClick={() =>
                          handleRemoveExistingImage(
                            "exterior",
                            null,
                            image,
                            index
                          )
                        }
                      >
                        <GiCancel size={12} />
                      </button>
                    </div>
                  ))}
                  {exteriorNewImages?.map((image, index) => (
                    <div
                      key={index}
                      className="flex flex-col relative items-center mr-2"
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index + 1}`}
                        style={{
                          minWidth: "200px",
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />

                      <button
                        className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                        onClick={() =>
                          handleRemoveNewImage("exterior", null, index)
                        }
                      >
                        <GiCancel size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        {step === 4 && (
          <>
            {" "}
            <div className="rounded-sm shadow-lg border-gray-600 border-2 p-3 mt-4">
              {/*interior title */}
              <div className="">
                {" "}
                <div className={styles.labelStyle} htmlFor="interior">
                  {" "}
                  Interior Title :{" "}
                </div>
                <div className="">
                  {editorLoaded ? (
                    <Controller
                      name="interior.title"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <CKEditor
                          ref={interiorTitleRef}
                          editor={ClassicEditor}
                          id="rich-text-editor"
                          data={field.value || ""}
                          onReady={(editor) => {
                            editor?.editing?.view?.change((writer) => {
                              writer?.setStyle(
                                "height",
                                "200px",
                                editor?.editing?.view?.document?.getRoot()
                              );
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor?.getData();
                            field.onChange(data);
                            setData((prevState) => ({
                              ...prevState,
                              interior: {
                                ...prevState.interior,
                                title: data,
                              },
                            }));
                          }}
                        />
                      )}
                    />
                  ) : (
                    <div>Editor Loading</div>
                  )}
                  {errors?.interior?.title && (
                    <p className="mt-2 text-red-500">
                      {errors.interior.title.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Upload Interior Images */}
              <div className="w-full flex flex-col items-center gap-4">
                <label htmlFor="img_input" className={styles.img_label}>
                  Upload Interior Images
                  <input
                    id="img_input"
                    className={styles.img_input}
                    multiple
                    type="file"
                    accept="image/*"
                    onChange={(e) => onUpload(e, "interior")}
                  />
                </label>
                {err && <p className="mt-2 text-red-500">{err}</p>}
                <div
                  className={`flex flex-row h-[200px] w-full overflow-hidden overflow-x-auto  border border-dashed border-[#000f3c]`}
                >
                  {interiorExistingImages?.map((image, index) => (
                    <div
                      key={index}
                      className="flex flex-col relative items-center mr-2"
                    >
                      <img
                        src={`/bucket/motor${image}`}
                        alt={`Preview ${index + 1}`}
                        style={{
                          minWidth: "200px",
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />

                      <button
                        className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                        onClick={() =>
                          handleRemoveExistingImage(
                            "interior",
                            null,
                            image,
                            index
                          )
                        }
                      >
                        <GiCancel size={12} />
                      </button>
                    </div>
                  ))}
                  {interiorNewImages?.map((image, index) => (
                    <div
                      key={index}
                      className="flex flex-col relative items-center mr-2"
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index + 1}`}
                        style={{
                          minWidth: "200px",
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />

                      <button
                        className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                        onClick={() =>
                          handleRemoveNewImage("interior", null, index)
                        }
                      >
                        <GiCancel size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        {step === 5 && (
          <div className="w-full space-y-5 laptop:flex laptop:flex-col laptop:items-center laptop:justify-evenly laptop:space-y-2">
            <div className="w-[93%] flex items-center gap-6">
              <div className={`${styles.label}`}>Specification *</div>
              <button
                className="w-4 h-4 rounded-full border border-black flex items-center justify-center text-center"
                onClick={handleSpecificationAddition}
              >
                +
              </button>
            </div>

            <div className="flex flex-col gap-3 w-[93%]">
              {specifications?.map((field, i) => (
                <SpecificationField
                  key={field._id}
                  index={i}
                  field={field}
                  onChange={handleSpecificationChange}
                  onRemove={() => handleSpecificationReduceField(i)}
                  showRemoveButton={i > 0}
                />
              ))}
            </div>
            <div className="grid grid-cols-2 gap-4 w-full">
              {/* Vehicle Type */}
              <div className="w-full">
                <label
                  htmlFor="vehicle-type"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Select Vehicle Type
                </label>
                <select
                  id="vehicle-type"
                  className="w-full rounded-md border border-gray-300 p-2"
                  value={data.type || ""}
                  onChange={(e) => {
                    const selectedType = e.target.value;
                    setData((prevData) => ({
                      ...prevData,
                      type: selectedType,
                    }));
                  }}
                >
                  <option value="" disabled>
                    Select a type
                  </option>
                  <option value="ice">ICE</option>
                  <option value="hybrid">Hybrid</option>
                  <option value="ev">EV</option>
                </select>
              </div>

              {/* Specification File */}
              <div className="w-full">
                <label
                  htmlFor="specification_file"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Specification File
                </label>
                <select
                  id="specification_file"
                  {...register("specification_file", {
                    required: "File is required",
                  })}
                  value={watch("specification_file")}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => {
                    setValue("specification_file", e.target.value);
                    setData((prevData) => ({
                      ...prevData,
                      specification_file: e.target.value,
                    }));
                  }}
                >
                  <option value="">-- Select File --</option>
                  {specificationFile?.map((file) => (
                    <option key={file._id} value={file._id}>
                      {file.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Is Manual */}
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="is_manual"
                  className="w-4 h-4"
                  checked={data.is_manual}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setData((prevData) => ({
                      ...prevData,
                      is_manual: isChecked,
                    }));
                  }}
                />
                <label htmlFor="is_manual" className="text-sm text-gray-700">
                  Is Manual
                </label>
              </div>

              {/* Rate */}
              <div className="w-full">
                <label
                  htmlFor="rate"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Rate
                </label>
                <input
                  type="text"
                  id="rate"
                  placeholder="Enter rate"
                  className="w-full rounded-md border border-gray-300 p-2"
                  value={data.rate || ""}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      rate: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            {/* Upload demo Images */}
            <div className="w-full flex flex-col items-center gap-4">
              <label htmlFor="img_input" className={styles.img_label}>
                Upload Demo Images
                <input
                  id="img_input"
                  className={styles.img_input}
                  multiple
                  type="file"
                  accept="image/*"
                  onChange={(e) => onUpload(e, "demo")}
                />
              </label>
              <div
                className={`flex flex-row h-[200px] w-full overflow-hidden overflow-x-auto  border border-dashed border-[#000f3c]`}
              >
                {demoExistingImages?.map((image, index) => (
                  <div
                    key={index}
                    className="flex flex-col relative items-center mr-2"
                  >
                    <img
                      src={`/bucket/motor${image}`}
                      alt={`Preview ${index + 1}`}
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />

                    <button
                      className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                      onClick={() =>
                        handleRemoveExistingImage("demo", null, image, index)
                      }
                    >
                      <GiCancel size={12} />
                    </button>
                  </div>
                ))}
                {demoNewImages?.map((image, index) => (
                  <div
                    key={index}
                    className="flex flex-col relative items-center mr-2"
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index + 1}`}
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />

                    <button
                      className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                      onClick={() => handleRemoveNewImage("demo", null, index)}
                    >
                      <GiCancel size={12} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {step < 5 && (
          <div className="flex items-center justify-end space-x-5">
            <PrimaryButton onClicking={onClose}>
              <div className="px-4 py-3">Cancel</div>
            </PrimaryButton>
            <PrimaryButton onClicking={handleBack} isDisabled={step === 1}>
              <div className="px-4 py-3">Back</div>
            </PrimaryButton>
            <PrimaryButton
              onClicking={() => handleNext()}
              isDisabled={isNextDisabled}
            >
              <div className="px-4 py-3">Next</div>
            </PrimaryButton>
          </div>
        )}
        {step === 5 && (
          <div className="flex items-center justify-end space-x-5">
            <PrimaryButton onClicking={onClose}>
              <div className="px-4 py-3">Cancel</div>
            </PrimaryButton>
            <PrimaryButton onClicking={handleBack} isDisabled={step === 1}>
              <div className="px-4 py-3">Back</div>
            </PrimaryButton>
            <PrimaryButton
              onClicking={() => onConfirm(data, removeImages, specifications)}
              isDisabled={isNextDisabled}
            >
              <div className="px-4 py-3">
                {type === "createForm" ? "Create" : "Update"}
              </div>
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditionForm;
